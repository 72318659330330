import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-toastify";
import { add_address } from "../../../../../../api/addressApi";
import { CiLocationArrow1 } from "react-icons/ci";
import { GoHome } from "react-icons/go";
import { MdOutlineLocationOn, MdOutlineWorkOutline } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import AddressLocation from "./AddressLocation";
import AddLocationsIdx from "../addLocationV2/AddLocationsIdx";
// import SelectLocationIdxv3 from "../googleLocationSelection/SelectLocationIdx3";

export default function AddressAdd({
  parentsStates,
  callback,
  setParentsStates,
}) {
  return (
    <AnimatePresence>
      {parentsStates?.isAddressAdd && (
        <Modal
          setParentsStates={setParentsStates}
          parentsStates={parentsStates}
          callback={callback}
        />
      )}
    </AnimatePresence>
  );
}
const Modal = ({ parentsStates, setParentsStates, callback }) => {
  const handleClose = () =>
    setParentsStates((p) => ({
      ...p,
      isAddressAdd: false,
    }));

  const [states, setStates] = useState({
    isLoading: false,
    addressLabel: "home",
    address1: "",
    address2: "",
    location: "",
    hubId: "",
    receiversDetails: {
      name: parentsStates.userInfo?.name || "",
      mobileNumber: parentsStates.userInfo?.phoneNumber || "",
      type: "",
    },
    locationv2: {
      lat: "",
      lng: "",
      addressLine1: "",
      city: "",
      state: "",
      pincode: "",
      addressLine2: "",
    },
    isEditReceiversDetails: false,
    isLocationAddModalRender: false,
  });
  const [isOther, setIsOther] = useState(false);
  const onNext = async () => {
    if (!states.address1 || !states.locationv2?.lat) {
      toast.warning("Need to set location and address line 1");
      return;
    }
    try {
      setStates((prev) => ({ ...prev, isLoading: true }));

      const res = await add_address({
        data: {
          userId: parentsStates.userInfo._id,
          addressLabel: states.addressLabel,
          address1: states.address1,
          address2: states.address2,
          location: states.location,
          locationv2: states.locationv2,
          receiversDetails: {
            name: states.receiversDetails?.name,
            mobileNumber: states.receiversDetails?.mobileNumber,
            type: states.receiversDetails?.type,
          },
        },
      }); // Call the API function for creating a Santa
      if (res.isSuccess) {
        callback();
        handleClose();
      } else {
        toast.error(res.message);
      }
      setStates((prev) => ({ ...prev, isLoading: false }));
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div
      className="fixed top-0 right-0 left-0 bottom-0
 flex items-end justify-start z-[999] "
    >
      <div
        onClick={handleClose}
        className="partial-black-background bg-[#0000009e]"
      ></div>
      <motion.div
        initial={{
          translateY: 800,
        }}
        animate={{
          translateY: 0,
        }}
        exit={{
          translateY: 800,
        }}
        style={{
          height: "calc( 100vh - 6rem)",
        }}
        transition={{ ease: "easeInOut" }}
        className="w-full max-w-[100vw] h-[80vh]
           flex items-center rounded-t-xl overflow-hidden
            drop-shadow border-t px-4 bg-[#ffffff] z-[2] text-[.875rem] flex-col "
      >
        {states.isLoading && (
          <div
            className="absolute text-white gap-2 top-0 right-0 left-0 bottom-0
                 flex items-center justify-center bg-[#000000a5] z-[1]"
          >
            <div
              style={{ borderTopColor: "white" }}
              className="loader w-[1rem]"
            ></div>
            Saving...
          </div>
        )}
        <div className="w-full font-[500] flex items-center justify-between text-[1rem] mb-2 py-2 ">
          <div onClick={handleClose} className="text-[#2136d4] cursor-pointer">
            Cancel
          </div>
          <div className=" flex flex-col justify-center items-center ">
            <div className="text-[1.125rem] font-[500] ">New address</div>
          </div>
          <div onClick={onNext} className="text-[#2136d4] cursor-pointer">
            Save
          </div>
        </div>

        <div
          className="rounded-t-xl overflow-scroll  p-2
          relative flex flex-col bg-[#f5f5f5] w-full h-full "
        >
          <AddressLocation parentStates={states} setParentStates={setStates} />

          <AddLocationsIdx
            locationData={states}
            setLocationData={setStates}
            callBack={(locationv2) => setStates((p) => ({ ...p, locationv2 }))}
          />

          <div className="text-[.75rem] text-[gray] px-1">Owners details</div>
          <input
            type="text"
            value={states?.receiversDetails.name}
            onChange={(e) =>
              setStates((p) => ({
                ...p,
                receiversDetails: {
                  ...p.receiversDetails,
                  name: e.target.value,
                },
              }))
            }
            className="input2 mb-2"
            placeholder="Enter receiver's name"
          />
          <input
            type="number"
            value={states?.receiversDetails.mobileNumber}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setStates((p) => ({
                ...p,
                receiversDetails: {
                  ...p.receiversDetails,
                  mobileNumber: parseInt(e.target.value),
                },
              }));
            }}
            className="input2 mb-2"
            placeholder="Enter receiver's phone number"
          />
          <div className="text-[.75rem] text-[gray] px-1">Address 1</div>
          <input
            value={states.address1}
            onChange={(e) => {
              setStates((p) => ({ ...p, address1: e.target.value }));
            }}
            placeholder="address line 1"
            className="input2 mb-2"
          />
          <div className="text-[.75rem] text-[gray] px-1">Address 2</div>
          <input
            value={states.address2}
            onChange={(e) => {
              setStates((p) => ({ ...p, address2: e.target.value }));
            }}
            placeholder="address line 2"
            className="input2 mb-2"
          />
          <div className=" text-[.825rem] mt-4 mb-1 text-[grey]  ">
            Save address as
          </div>
          {isOther ? (
            <div className="flex text-[.825rem] items-end mt-1 font-[500] ">
              <div
                style={
                  isOther
                    ? {
                        backgroundColor: "#F0F5FE",
                        borderColor: "#a3c1fe",
                      }
                    : {}
                }
                className="border cursor-pointer border-[#eeeeee] px-2 mr-2 flex items-center gap-1 py-1 rounded-md "
              >
                <MdOutlineLocationOn className="relative bottom-[1px] " />
                Other
              </div>
              <input
                type="text"
                value={states?.addressLabel}
                onChange={(e) =>
                  setStates((p) => ({
                    ...p,
                    addressLabel: e.target.value,
                  }))
                }
                className="input2 "
                placeholder="Type here"
              />
              <RxCross2
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    addressLabel: "home",
                  }));
                  setIsOther(false);
                }}
                className="text-[1.5rem] pb-2 cursor-pointer border-b"
              />
            </div>
          ) : (
            <div className="flex text-[.825rem] mt-1 font-[500] gap-4">
              <div
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    addressLabel: "home",
                  }));
                }}
                style={
                  states?.addressLabel === "home"
                    ? {
                        backgroundColor: "#F0F5FE",
                        borderColor: "#a3c1fe",
                      }
                    : {}
                }
                className="border  cursor-pointer bg-[white] border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
              >
                <GoHome className="relative bottom-[1px] " />
                Home
              </div>
              <div
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    addressLabel: "venue",
                  }));
                }}
                style={
                  states?.addressLabel === "venue"
                    ? {
                        backgroundColor: "#F0F5FE",
                        borderColor: "#a3c1fe",
                      }
                    : {}
                }
                className="border cursor-pointer bg-[white]  border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
              >
                <CiLocationArrow1 className="relative bottom-[1px] " />
                Venue
              </div>
              <div
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    addressLabel: "work",
                  }));
                }}
                style={
                  states?.addressLabel === "work"
                    ? {
                        backgroundColor: "#F0F5FE",
                        borderColor: "#a3c1fe",
                      }
                    : {}
                }
                className="border cursor-pointer bg-[white]  border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
              >
                <MdOutlineWorkOutline className="relative bottom-[1px] " />
                Work
              </div>
              <div
                onClick={() => {
                  setStates((p) => ({
                    ...p,
                    addressLabel: "",
                  }));
                  setIsOther(true);
                }}
                style={
                  isOther
                    ? {
                        backgroundColor: "#F0F5FE",
                        borderColor: "#a3c1fe",
                      }
                    : {}
                }
                className="border cursor-pointer bg-[white]  border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
              >
                <MdOutlineLocationOn className="relative bottom-[1px] " />
                Other
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

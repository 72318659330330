import React, { useMemo } from "react";
import { useStoreContext } from "../../../../context/StoreContext";
import { FaBolt, FaTag } from "react-icons/fa";
import { calculate_offer_discount } from "../../../../functions/calculateLowesPrice";

export default function ProductCard({ product, hubId, setOrderStates }) {
  const {
    storeStates: { defaultDecorDiscount },
  } = useStoreContext();

  const productPriceDetail = product.listingPriceHubWise?.find(
    (currHub) => currHub.hub === hubId
  );

  const isOnBooking = product?.tags?.includes("on-demand booking");

  const onProductClick = () => {
    setOrderStates((p) => ({
      ...p,
      product: {
        ...product,
        ...productPriceDetail,
        _id: product._id,
      },
    }));
  };

  const discount = useMemo(() => {
    // the discount for the decor applied by universal state
    return calculate_offer_discount(productPriceDetail?.sellingPrice, {
      defaultDecorDiscount,
    });
  }, [productPriceDetail?.sellingPrice, defaultDecorDiscount]);

  return (
    <div className={`flex flex-col relative w-[11rem] `}>
      <img
        src={product?.images[0]}
        alt={product.name}
        className={`w-full aspect-square border cursor-pointer border-[#e6e6e6] rounded-lg `}
        loading="lazy"
        width="100%"
        height="100%"
        title={product.name}
        onClick={() => {
          onProductClick();
        }}
      />
      <div
        onClick={() => {
          onProductClick();
        }}
        className="flex leading-5 tracking-[.6px] cursor-pointer flex-col px-2 pt-1 pb-2"
      >
        <p className="  text-xs font-[500]  ">{product.name}</p>
        {!hubId || !productPriceDetail ? (
          <div
            onClick={() => {
              window.showMsg(
                "Select a hub from right-top corner button, to view price"
              );
            }}
            className="bg-blue-50 text-2xs px-1 py-1 border cursor-pointer font-medium border-[#2136d4] text-center mt-2 text-[#2136d4] rounded-lg"
          >
            {!hubId ? "Select a address" : "Price not set in this address"}
          </div>
        ) : (
          <>
            {isOnBooking ? (
              <>
                <p className="flex gap-3 mt-2 font-[500] text-[.825rem] items-center">
                  *Price on request
                </p>
                <p className="mirror-Animation font-[500] before:w-[20px] bg-gradient-to-r  from-blue-50 to-white rounded-[15px] p-1 px-2  mt-[2px] relative right-[4px] flex items-center gap-1  text-[.75rem] text-[#2136d4]  md:text-[.825rem]">
                  Enquire for price
                </p>
              </>
            ) : (
              <>
                <p className="flex gap-2 mt-2 whitespace-nowrap  items-center">
                  <span className=" font-[600] text-sm ">
                    ₹{productPriceDetail?.sellingPrice}
                  </span>
                  <span className=" font-[400] text-xs  text-[gray] line-through ">
                    ₹{productPriceDetail?.costPrice}
                  </span>
                  <span className="  text-[#00a807] text-xs  font-[400]">
                    {productPriceDetail?.webDiscount}% off
                  </span>
                </p>

                <div className="mirror-Animation before:w-[20px] bg-gradient-to-r  text-xs  from-blue-50 to-white rounded-[15px] p-1 px-2 font-[400] mt-2 relative right-[4px] flex items-center gap-1  text-[.75rem] text-[#2136d4]  md:text-[.825rem]">
                  <FaBolt className="text-[#2136d4] text-[.725rem]  animate-offer-icon " />

                  <span className="  font-[600] ">
                    ₹{productPriceDetail?.sellingPrice - discount}
                  </span>

                  <FaTag className="text-yellow-500 ml-2" />
                  <span className="font-semibold text-yellow-700">
                    ₹{discount}
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

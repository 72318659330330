export const serviceCities = ["siliguri", "mumbai", "pune"];

export const inventoryItemsCategories = [
  "balloon",
  "balloons",
  "ring",
  "foil",
  "light",
  "freebie",
  "occassion banners",
  "character foil",
  "lights",
  "consumables",
  "stands",
  "alphabet foil",
  "number foil",
  "flowers",
  "accessories",
];

export const productTagOptions = [
  "balloon",
  "ring",
  "foil",
  "light",
  "premium",
  "outdoor",
  "indoor",
  "flower",
  "u ring",
  "l ring",
  "n ring",
  "o ring",
  "arch ring",
  "neon",
  "wall",
  "birthday",
  "byod",
  "room",
  "store",
  "chrome",
];

export const santaWalletTransactionReasons = [
  "order",
  "deposit",
  "cashInHand",
  "traveling",
  "other",
];

export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILED = "FETCH_FAILED";
export const CHANGE_LOADING_STATE = "CHANGE_LOADING_STATE";
export const RELOAD_PAGE = "RELOAD_PAGE";
export const UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE";
export const UPDATE_ONE_ANALYTICS_DATA = "UPDATE_ONE_ANALYTICS_DATA";
export const FORMAT_MONTH_WISE = "FORMAT_MONTH_WISE";
export const FORMAT_DAY_WISE = "FORMAT_DAY_WISE";
export const FORMAT_HOURLY_WISE = "FORMAT_HOURLY_WISE";

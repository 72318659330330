import axios from "axios";

const BaseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("ACCESS_TOKEN");

// -------------------Delete AddOn------------------
const deleteAddOnAPI = async (id) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/api/admin/addOn/deleteAddOn`,
      {
        addonId: id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

// -------------------To add AddOn------------------
const AddManyAddOnAPI = async ({ addonsArray }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/addOn/uploadArrayOfAddons`,
      { addonsArray },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res;
  } catch (error) {
    console.log(error);
    return { isSuccess: false, message: error };
  }
};

// -------------------to add addon ------------------
const addAddonAPI = async (addOn) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/addOn/addAddOn`,
      { addOn },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};

const get_addon_by_query_obj = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
  projectionString,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/addOn/get_addon_by_query_obj`,
      {
        queryObject: queryObject || {},
        skip: skip || 0,
        limit: limit || 500,
        sortingObj: sortingObj || { createdAt: "desc" },
        projectionString: projectionString || "",
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};

const update_addon_by_query_obj = async ({ queryObject, updateObject }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/addOn/update_addon_by_query_obj`,
      {
        queryObject,
        updateObject,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};
const get_addons_count_type_wise_api = async () => {
  try {
    const res = await axios.get(
      `${BaseUrl}/api/admin/addOn/addons-count-type-wise`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false };
  }
};

export {
  addAddonAPI,
  deleteAddOnAPI,
  AddManyAddOnAPI,
  get_addon_by_query_obj,
  update_addon_by_query_obj,
  get_addons_count_type_wise_api,
};

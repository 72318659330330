import axios from "axios";
const BaseUrl = process.env.REACT_APP_API_URL;
const placeOrder = async (data) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/place`,
      { data },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};

const handleUploadOrderImages = async (images) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/handleUploadOrderImages`,
      images,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};

const get_orders_by_query_obj = async ({
  queryObject = {},
  skip = 0,
  limit = 2000,
  sortingObj = { createdAt: "desc" },
  projectionString = "",
  options = {},
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/get_order_by_query_object`,
      {
        queryObject,
        skip: skip || 0,
        limit: limit || 2000,
        sortingObj,
        projectionString: projectionString || "",
        options,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const getOrdersDetailPageInfoApi = async ({ orderId }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/getOrdersDetailPageInfo`,
      {
        orderId,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

const santa_payout_declaration_in_order = async ({
  orderId,
  santaId,
  payout,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/santa_payout_declaration_in_order`,
      {
        orderId,
        santaId,
        payout,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const update_orders_by_query_obj = async ({
  queryObject,
  updateObject,
  tags,
  extraInfo,
  adminEventInfo,
  orderEventInfo,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/update_order_by_query_object`,
      {
        queryObject,
        updateObject,
        tags,
        extraInfo,
        adminEventInfo,
        orderEventInfo,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const delete_by_query_obj = async ({ queryObject }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/delete_by_query_obj`,
      {
        queryObject,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const handleOrderCompletionApi = async ({ orderId }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/handle_order_completion`,
      {
        orderId,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const handleOrderStatusChangeApi = async ({
  orderId,
  operation,
  queryValues = {},
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/handleOrderStatusChange`,
      {
        orderId,
        operation,
        queryValues,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const handleOrderConfirmationApi = async ({ orderId }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/order/handle_order_confirmation`,
      {
        orderId,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

export {
  get_orders_by_query_obj,
  update_orders_by_query_obj,
  placeOrder,
  delete_by_query_obj,
  santa_payout_declaration_in_order,
  handleOrderCompletionApi,
  handleOrderStatusChangeApi,
  handleOrderConfirmationApi,
  handleUploadOrderImages,
  getOrdersDetailPageInfoApi,
};

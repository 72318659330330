import React from "react";
import { FaAngleRight, FaLocationArrow } from "react-icons/fa";

export default function AddressLocation({ parentStates, setParentStates }) {
  if (parentStates?.locationv2?.lat) {
    return (
      <div
        onClick={() => {
          setParentStates((p) => ({ ...p, isLocationAddModalRender: true }));
        }}
        className="flex bg-[white] mb-2 w-full justify-between items-center border  cursor-pointer border-[#eeeeee] px-2 py-2 rounded-md "
      >
        <div className="flex flex-col w-[85%] ">
          <div className="flex gap-2 items-center">
            <FaLocationArrow className="text-[#2136d4] text-[.825rem] " />
            <div className="flex gap-1 items-center">
              <span className="text-[grey]">Service at </span>
              <span className="text-[black] font-[500] max-w-[6rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
                {parentStates?.locationv2?.addressLine1}
              </span>
            </div>
          </div>
          <span className="text-[grey] text-[.75rem] max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
            {parentStates?.locationv2?.addressLine2}
          </span>
        </div>
        <button className=" w-[15%] text-[#2136d4] px-2 py-[2px] inline-block font-[500] text-[.725rem]">
          Change
        </button>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          setParentStates((p) => ({ ...p, isLocationAddModalRender: true }));
        }}
        className="flex bg-[white] mb-2 justify-between items-center border  cursor-pointer border-[#eeeeee] px-2 py-2 rounded-md "
      >
        <div className="flex flex-col  ">
          <div className="font-[500] flex items-center gap-1 capitalize text-[.875rem] ">
            <FaLocationArrow className="text-[#2136d4] text-[1rem]" /> Select
            Location
          </div>
        </div>
        <div className="text-[.825rem] text-[#2136d4] font-[500]  ">
          <FaAngleRight className="" />
        </div>
      </div>
    );
  }
}

import axios from "axios";

const BaseUrl = process.env.REACT_APP_API_URL;
// Function to create a new Santa
const createNewSanta = async (santaData) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/api/admin/santa/create`,
      { santaData },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error creating a new Santa:", error);
    throw error;
  }
};

const deleteSantaPermanentlyApi = async ({ options }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/santa`,
      { options },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};

const getSantasInfoByQueryApi = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
  projectionString,
  conditions,
  tags,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/api/admin/santa/get_by_query`,
      {
        queryObject,
        skip: skip || 0,
        limit: limit || 10000,
        sortingObj: sortingObj || {},
        projectionString: projectionString || "",
        conditions: conditions || {},
        tags: tags || [],
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error creating a new Santa:", error);
    throw error;
  }
};

const update_santa_by_query_obj = async ({
  queryObject,
  updateObject,
  tags,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/api/admin/santa/update_santa_by_query_obj`,
      {
        queryObject,
        updateObject,
        tags,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false },
    };
  }
};

const santa_payout_create_api = async ({
  santaId,
  payout,
  paymentMode,
  transactionId,
}) => {
  try {
    const LC_ADMIN_INFO = JSON.parse(localStorage.getItem("ADMIN_INFO"));

    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/api/admin/santa/santa_payout_create_api`,
      {
        santaId,
        from: LC_ADMIN_INFO.name,
        payout,
        paymentMode,
        transactionId,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false },
    };
  }
};
const santasAdminPageBaseData = async ({ options }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/api/admin/santa/`,
      { options },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};
const getSantaOrdersBySantaIdApi = async (_id) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/api/admin/santa/getSantaOrdersBySantaId`,
      { _id },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};
const santaWalletCrudHandlerApi = async (options) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${BaseUrl}/api/admin/santa/santaWalletCrudHandler`,
      { options },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

export {
  createNewSanta,
  getSantasInfoByQueryApi,
  update_santa_by_query_obj,
  santa_payout_create_api,
  santasAdminPageBaseData,
  getSantaOrdersBySantaIdApi,
  santaWalletCrudHandlerApi,
  deleteSantaPermanentlyApi,
};

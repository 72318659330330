import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";

/**
 * @param { viewMore: boolean, handleOnToggleBtnClick: function, className: inlineCSSClass } param0
 * @returns
 */
export default function ShowAllToggleBtn({
  viewMore,
  handleOnToggleBtnClick,
  className,
}) {
  return (
    <div
      className={`mx-auto text-[10px] md:text-sm flex justify-center items-center text-[#2136D4] cursor-pointer select-none ${className}`}
      onClick={handleOnToggleBtnClick}
    >
      <span>{`${viewMore ? "Collapse" : "View All"}`}</span>
      {viewMore ? (
        <MdOutlineExpandLess className="text-xs md:text-2xl" />
      ) : (
        <MdOutlineExpandMore className="text-xs md:text-2xl" />
      )}
    </div>
  );
}

import React from "react";
import { FaLocationArrow } from "react-icons/fa";

export default function PredictionsList({ states, setStates }) {
  const handlePredictionClick = async (prediction) => {
    try {
      setStates((p) => ({
        ...p,
        loadingPointLocation: true,
        showMap: true,
        defaultUi: false,
        query: "",
        searchBarResultAddress: prediction.description,
        isPredictionsShow: false,
      }));
      const placeId = prediction.place_id;
      const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

      const response = await fetch(geocodingUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        console.log(location);
        setStates((p) => ({
          ...p,
          lat: location.lat,
          lng: location.lng,
          showMap: true,
          defaultUi: false,
          isChangeMapPosition: true,
        }));
      } else {
        throw new Error("Error fetching place details");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (states.isPredictionsShow) {
    if (states.loadingPredictions) {
      const skeletonCount = new Array(5).fill(0);
      return (
        <div className="flex flex-col w-full ">
          <div className="uppercase text-[#afafaf] mb-6 font-[500] ">
            Search result
          </div>
          {skeletonCount.map((_, i) => {
            return (
              <div key={i} className="flex flex-col mb-4">
                <div className=" w-[5rem] h-[.875rem] rounded-md ebo_skeleton mb-2"></div>
                <div className=" w-[90%] h-[.875rem] rounded-md ebo_skeleton"></div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="flex flex-col w-full ">
          <div className=" uppercase text-[#afafaf] mb-6 font-[500] ">
            Search result
          </div>
          {states.predictions.map((currPrediction, i) => {
            return (
              <div
                onClick={() => {
                  handlePredictionClick(currPrediction);
                }}
                key={i}
                className="flex flex-col mb-6 cursor-pointer"
              >
                <div className="flex items-center gap-2 text-[1rem] text-[#555555] font-[500] mb-1">
                  <FaLocationArrow /> {currPrediction.description.split(",")[0]}
                </div>
                <div className="text-[gray] text-[.8125rem] w-[90%] text-ellipsis overflow-hidden whitespace-nowrap ">
                  {currPrediction.description}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

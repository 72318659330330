import React, { Fragment, useEffect, useState } from "react";
import { FaMapMarkerAlt, FaMobileAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { MdOutlineWorkOutline } from "react-icons/md";
import { GoHome } from "react-icons/go";
import { insideHubBoundary } from "../../../../functions/googlemap/availablityChecks";
import AddressAdd from "../../../userComponents/v2/userProfileSubcomponents/address/addressAdd/AddressAdd";
export default function AddressSelectorSection({
  orderStates,
  setOrderStates,
  setStates: setParentStates,
}) {
  // ---------------- CONTEXTS ------------
  const [allAddressOfUser, setAllAddressOfUser] = useState([]);
  const [states, setStates] = useState({
    userInfo: orderStates?.user,
    isAddressAdd: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAddresses();
    // eslint-disable-next-line
  }, [orderStates.user]);

  const fetchAddresses = async () => {
    if (!orderStates.user?.name) {
      return;
    }
    window.v2Crud({
      queries: [
        {
          modelName: "Addresses",
          operation: "read",
          sort: { createdAt: -1 },
          filter: {
            userId: orderStates.user._id,
            "locationv2.lat": { $ne: null },
          },
        },
      ],
      onInitiate: () => {
        setLoading(true);
      },
      onSuccess: (res) => {
        console.log("res?.data?.at(0)?.data", res?.data?.at(0)?.data);
        setAllAddressOfUser(res?.data?.at(0)?.data || []);

        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      },
    });
  };

  const handleAddressSelect = async (address) => {
    if (!address?.locationv2?.lat) {
      window.toast("Location is not set");
      return;
    }
    const isPointInPolygon = await insideHubBoundary([
      address.locationv2.lng,
      address.locationv2.lat,
    ]);

    if (isPointInPolygon.isInside) {
      setOrderStates((prev) => ({
        ...prev,
        hubId: isPointInPolygon.foundInWhichHub,
        // addOns: [],
        // product: null,
        bookingAddress: address, // Use an empty string as the default value if no address is found
      }));
      setParentStates((prev) => ({
        ...prev,
        currentSection: 2,
      }));
    } else {
      window.toast("Location is not serviceable");
    }
  };

  return (
    <Fragment>
      <div className="w-full capitalize flex gap-2 flex-col   ">
        <div className="flex flex-col w-full">
          {allAddressOfUser.length <= 0 && (
            <div className="flex items-center justify-center w-full text-[grey] h-[5rem]">
              No Saved addresses
            </div>
          )}
          {allAddressOfUser.map((currAddress) => {
            const addresslabel = currAddress?.addressLabel.trim().toLowerCase();
            const line2 =
              currAddress?.address1 +
              ", " +
              (currAddress?.address2 && currAddress?.address2 + ", ") +
              currAddress?.locationv2?.addressLine1 +
              ", " +
              currAddress?.locationv2?.addressLine2;

            return (
              <div
                onClick={() => {
                  handleAddressSelect(currAddress);
                }}
                style={{
                  border: "1px solid",
                  padding: "1rem",
                  marginBottom: "0.5rem",
                  borderColor: "#d9d9d9",
                }}
                className={` ${
                  orderStates.bookingAddress?._id === currAddress._id
                    ? "bg-[#ebf3f5] before:border-[#2136d4]"
                    : "before:border-[#dcdce0]"
                } before:w-4 before:h-4 before:absolute
                  before:rounded-full before:right-4 before:border-4 before:top-12   w-full flex gap-2 my-2 overflow-hidden b text-[.825rem] relative rounded-lg   font-[500]  `}
              >
                {currAddress?.locationv2?.lat && (
                  <a
                    href={`https://www.google.com/maps?q=${currAddress?.locationv2?.lat},${currAddress?.locationv2?.lng}`}
                    className="border-b cursor-pointer border-l bg-blue-50 flex items-center gap-1 border-gray-200 text-[#2136d4] absolute right-0 top-0 text-[.75rem] rounded-bl px-2 "
                  >
                    <FaMapMarkerAlt /> view on map
                  </a>
                )}

                {addresslabel === "home" ? (
                  <GoHome className="text-[2rem] text-[#2136d4]" />
                ) : addresslabel === "work" ? (
                  <MdOutlineWorkOutline className="text-[2rem] text-[#2136d4]" />
                ) : addresslabel === "friends and family" ? (
                  <HiOutlineUserGroup className="text-[2rem] text-[#2136d4]" />
                ) : (
                  <IoLocationOutline className="text-[2rem] text-[#2136d4]" />
                )}

                <div className="flex flex-col w-full text-[.825rem] text-[grey] ">
                  <div className="flex gap-2 mb-1 text-[black] capitalize items-center ">
                    <span className="font-[600] capitalize text-[1.125rem]">
                      {currAddress?.receiversDetails?.name}
                    </span>
                    <span className="border bg-gray-50 border-gray-200 text-[gray] text-[.625rem]  rounded px-2 ">
                      {addresslabel}
                    </span>
                  </div>

                  <div className=" leading-[18px] text-[gray] font-[400]  tracking-[.4px]">
                    {line2}
                  </div>

                  <div className="flex  gap-[2px] mt-2 items-center text-[gray] font-[400]">
                    <FaMobileAlt className=" relative bottom-[.5px] text-[.625rem]" />{" "}
                    {currAddress?.receiversDetails?.mobileNumber}
                  </div>
                </div>
              </div>
            );
          })}

          <div
            onClick={() => {
              if (orderStates.user?.name) {
                setStates((p) => ({
                  ...p,
                  isAddressAdd: true,
                }));
                // setIsAddAddressForm(true);
              } else {
                window.showMsg("Please select a user first.");
              }
            }}
            className={`cursor-pointer border-[#2136d4] font-[500] text-[#2136d4] border flex items-center justify-center py-3 px-4 relative rounded-lg mb-2 capitalize`}
          >
            Create new one
          </div>
        </div>
      </div>

      <AddressAdd
        parentsStates={states}
        callback={() => {
          fetchAddresses();
        }}
        setParentsStates={setStates}
      />
    </Fragment>
  );
}

import React from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function LoadingScreen1({ states, setStates }) {
  return (
    <AnimatePresence>
      {states.LoadingScreen1.isRender && (
        <Modal setStates={setStates} msg={states.LoadingScreen1.msg} />
      )}
    </AnimatePresence>
  );
}
const Modal = ({ msg }) => {
  return (
    <div className="fixed top-0 z-[99] flex items-center justify-center right-0 left-0 bottom-0 bg-[#000000b2]">
      <motion.div
        initial={{ scale: 1.05, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 1.05, opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        className="flex items-center justify-center flex-col min-w-[10rem] max-w-[16rem] min-h-[5rem] bg-[white] rounded-lg p-4  pt-6 "
      >
        <div className="loader w-[1.25rem]"></div>
        <div className="mt-3">{msg}</div>

        {/* <button className="text-[1.125rem] cursor-pointer font-[600] border-t-2 border-[#d9d9d9] p-1 w-[80%] ">
          OK
        </button> */}
      </motion.div>
    </div>
  );
};

import { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";
import {
  checkIfAnyChildIsSelectedOrNot,
  getSelectedNodeIds,
  resetNodeData,
} from "../utils/modalUtils";
import { BsFillTriangleFill } from "react-icons/bs";

export default function ApplyFilterModal({ parentStates, setParentStates }) {
  return (
    <AnimatePresence>
      {parentStates.applyFilterModal.isRender && (
        <Modal parentStates={parentStates} setParentStates={setParentStates} />
      )}
    </AnimatePresence>
  );
}

/**
 * type Node = {
 *  id: string,
 *  title: string,
 *  isSelected: boolean, // applicable only for leaf nodes & for other nodes you can use it but there is no use of this field
 *  childrens: Array<Node>
 * }
 */
function Modal({ parentStates, setParentStates }) {
  const { title, description, data, callbacks } =
    parentStates.applyFilterModal.options;

  const [rootNode, setRootNode] = useState(data);

  // { id, title, childrens : [ { id, title , childrens, isSelected },{ id, title , childrens, isSelected } ] }

  const handleCloseModal = useCallback(() => {
    setParentStates((p) => ({
      ...p,
      applyFilterModal: {
        isRender: false,
      },
    }));
  }, [setParentStates]);

  const handleSelectNode = useCallback(
    (id, isSelected) => () => {
      setRootNode(() => updateDataNode(rootNode, id, isSelected));
    },
    [rootNode]
  );

  const handleApply = useCallback(() => {
    const pathsOfSelectedNodes = getSelectedNodeIds(rootNode);
    callbacks.onApply(rootNode, pathsOfSelectedNodes);
    handleCloseModal();
  }, [callbacks, handleCloseModal, rootNode]);

  const handleClear = useCallback(() => {
    setRootNode(() => resetNodeData(rootNode));
  }, [rootNode]);

  const handleOnClose = useCallback(() => {
    callbacks.onCancel();
    handleCloseModal();
  }, [callbacks, handleCloseModal]);

  return (
    <div className="fixed left-0 bottom-0 z-[999]">
      {/* background partial black layout */}
      <div
        onClick={handleOnClose}
        className="fixed inset-0 z-[1] bg-[#00000092]"
      ></div>
      <motion.div
        initial={{ translateX: -400, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: -400, opacity: 0 }}
        transition={{ duration: 0.2, ease: "linear" }}
        className="py-4 w-screen md:w-[30rem] relative z-[2] flex flex-col md:rounded-tr-md md:rounded-br-md bg-[white] "
      >
        {/* heading */}
        <div className="flex  items-center gap-2 px-2">
          <IoIosArrowBack
            className="text-3xl cursor-pointer"
            onClick={handleOnClose}
          />
          <div>
            <h1 className="font-semibold text-lg md:text-xl">
              {title || "title"}
            </h1>
            <p className=" text-xs md:text-sm">{description || "title"}</p>
          </div>
        </div>
        <hr className="mt-3" />
        <div
          style={{ height: "calc(100vh - 5.8rem)" }}
          className=" overflow-y-auto"
        >
          <MainSection data={rootNode} handleSelectNode={handleSelectNode} />
        </div>
        <div className=" absolute bottom-0 w-full px-[4%] flex justify-end gap-4 bg-white border-t-2 py-4 shadow-lg">
          <button
            className=" bg-gray-300 px-2 py-1 rounded-md text-sm"
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className="bg-[#2134d4] text-white px-2 py-1 rounded-md text-sm"
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </motion.div>
    </div>
  );
}

const MainSection = ({ data, handleSelectNode }) => {
  const [idx, setIdx] = useState(1);

  const handleOnClick = useCallback(
    (newIdx) => {
      if (idx === newIdx) return;
      setIdx(() => 0);
      setTimeout(() => {
        setIdx(() => newIdx);
      }, 1);
    },
    [idx]
  );

  return (
    <div className="flex size-full border-t border-b">
      <div className="flex flex-col border-r bg-blue-100 ">
        {data?.childrens?.map((node, ind) => (
          <div key={node.id + "-" + ind + 1} className="">
            <div
              className="min-w-[5rem] cursor-pointer text-sm capitalize whitespace-nowrap flex items-end py-2 border-b-2 border-gray-300 relative"
              onClick={() => handleOnClick(ind + 1)}
            >
              <div className="ps-4 text-sm font-semibold text-gray-700">
                {node.title}
              </div>
              {checkIfAnyChildIsSelectedOrNot(node) && (
                <div className=" absolute top-[20%] right-[10%] rounded-full bg-[#2134d4] size-1"></div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        {!!idx && (
          <Menu
            node={data?.childrens?.at(idx - 1)}
            handleSelectNode={handleSelectNode}
          />
        )}
      </div>
    </div>
  );
};

const Menu = ({ node, handleSelectNode }) => {
  return (
    <div>
      {node?.childrens?.map((childNode, idx) => (
        <MenuItem node={childNode} handleSelectNode={handleSelectNode} />
      ))}
    </div>
  );
};

const MenuItem = ({ node, handleSelectNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <div className="ps-2">
      <div className="min-w-[5rem] cursor-pointer text-sm capitalize whitespace-nowrap flex items-center gap-[2px] py-1">
        {/* check && triangle */}
        {node?.childrens ? (
          <div className="flex gap-1 w-full">
            <BsFillTriangleFill
              className={`${isOpen ? "rotate-180" : "rotate-90"} text-[9px]`}
            />
            <span
              className=" text-sm font-semibold text-gray-600"
              onClick={handleToggleMenu}
            >
              {node.title}
            </span>
          </div>
        ) : (
          <div
            className="flex gap-1 font-semibold text-gray-600 w-full"
            onClick={handleSelectNode(node.id, !node.isSelected)}
          >
            <input type="checkbox" checked={node.isSelected} />
            <span className="text-sm">{node.title}</span>
          </div>
        )}
      </div>
      {node?.childrens && isOpen && (
        <Menu node={node} handleSelectNode={handleSelectNode} />
      )}
    </div>
  );
};

/**
 * type Node = {
 *  id: string,
 *  title: string,
 *  isSelected: boolean,
 *  childrens: Array<Node>
 * }
 *
 * @param {Node} node // : Node
 * @param {string} id // : Node.id
 * @param {boolean} isSelected  // : boolean : Node.isSelected
 * @returns {Node} // : Node
 *
 *
 *  **isSelected applicable only for leaf nodes & for other nodes you can use it but there is no use of this field
 */
const updateDataNode = (node, id, isSelected) => {
  const tNode = JSON.parse(JSON.stringify(node));

  if (tNode.id === id) {
    tNode.isSelected = isSelected;
    return tNode;
  }

  if (tNode?.childrens) {
    for (let i = 0; i < tNode.childrens.length; i++) {
      tNode.childrens[i] = updateDataNode(
        tNode.childrens.at(i),
        id,
        isSelected
      );
    }
  }

  return tNode;
};

import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { BsFillBox2HeartFill, BsPersonBoundingBox } from "react-icons/bs";
import { TbCheckupList } from "react-icons/tb";
import MoreNavOptionsModal from "./MoreNavOptionsModal";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

export default function BottomNav() {
  const [showMoreNavOptions, setShowMoreNavOptions] = useState(false);

  return (
    <div className=" z-[16]  overflow-scroll fixed bottom-0 left-0 right-0 items-center py-2 px-4 flex justify-between text-[#171717] bg-white border-t ">
      {navLinks.map((curr, idx) => {
        return (
          <Link
            key={idx}
            to={curr.path}
            className=" flex flex-col items-center gap-1 cursor-pointer justify-center "
          >
            {curr.icon}
            <span className="text-[.625rem]">{curr.lable}</span>
          </Link>
        );
      })}

      <div
        onClick={() => {
          setShowMoreNavOptions(true);
        }}
        className=" flex flex-col items-center gap-1 cursor-pointer justify-center "
      >
        <GiHamburgerMenu className="text-[1.5rem] cursor-pointer " />
        <span className="text-[.625rem]">More</span>
      </div>

      <MoreNavOptionsModal
        showMoreNavOptions={showMoreNavOptions}
        setShowMoreNavOptions={setShowMoreNavOptions}
      />
    </div>
  );
}

const navLinks = [
  {
    lable: "Home",
    icon: <AiFillHome className="text-[1.5rem]" />,
    path: "/",
  },
  {
    lable: "Users",
    icon: <BsPersonBoundingBox className="text-[1.5rem]" />,
    path: "/user",
  },
  {
    lable: "Orders",
    icon: <BsFillBox2HeartFill className="text-[1.5rem]" />,
    path: "/order",
  },
  {
    lable: "Products",
    icon: <TbCheckupList className="text-[1.5rem]" />,
    path: "/product",
  },
];

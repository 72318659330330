import React from "react";
import AddNewAddonsModal from "./AddNewAddonsModal";
import { MdDelete } from "react-icons/md";
import { formatCurrency } from "../../../../utils/adminUtils";

export default function AddonsSelection({
  states,
  setStates,
  orderStates,
  setOrderStates,
}) {
  return (
    <div className="w-full rounded-lg border border-gray-200 bg-[white] flex flex-col mt-2 items-center p-2 gap-2 relative">
      <div className="font-[600] flex items-center border-b pb-2 justify-between w-full">
        <span className="text-[1.125rem]">Selected Addons</span>
        <div className="flex items-center gap-2">
          <span
            onClick={() => {
              window.floatingModal(
                true,
                <AddNewAddonsModal
                  orderStates={orderStates}
                  setOrderStates={setOrderStates}
                />,
                {
                  position: "r",
                  animation: window.innerWidth > 1024 ? "r-l" : "b-t",
                  animationLength: window.innerWidth > 1024 ? "100vw" : "100vh",
                }
              );
            }}
            className="flex gap-2 border text-[.825rem] text-[#2136d4] rounded-lg bg-[#ebf3f5] py-1 px-2  cursor-pointer items-center"
          >
            Add add-on
          </span>
        </div>
      </div>
      {orderStates.addOns?.length === 0 ? (
        <div className="flex-center h-[5rem] w-full text-gray-500">
          No add-ons
        </div>
      ) : (
        <ul className="divide-y w-full ">
          {orderStates?.addOns.map((item, idx) => (
            <li key={idx} className=" flex items-start justify-between">
              <div className="flex gap-3">
                <img
                  onClick={() => {
                    window.showMsg(item.addonImage, "img");
                  }}
                  src={item.addonId?.addonImage}
                  className="flex-none size-[3.5rem] border border-gray-200 rounded-md"
                  alt=""
                />
                <div>
                  <span className=" text-sm text-gray-700 font-semibold ">
                    {item.addonId?.addonName}
                  </span>
                  <span className=" text-xs flex items-center ">
                    {item.text
                      ? "Content: " + item.text
                      : "qty: " + item.quantity}

                    <div className="gray-gradient p-[2px] rounded-full mx-1"></div>
                    <div
                      onClick={() => {
                        // removing the addon from array using the idx
                        setOrderStates((prev) => {
                          const newAddons = [...prev.addOns];
                          newAddons.splice(idx, 1);
                          return { ...prev, addOns: newAddons };
                        });
                      }}
                      className="flex items-center text-red-600 cursor-pointer"
                    >
                      Remove <MdDelete className="text-2xs mt-[1px]" />
                    </div>
                  </span>
                </div>
              </div>
              <div className="text-base font-[600] ">
                {formatCurrency(item.addonId?.discountedPrice)}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

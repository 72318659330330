import { createContext, useContext, useState } from "react";
import { signInApi } from "../api/userApi";
import StoreContext from "./StoreContext";
// import { isBrowser, isMobile } from "react-device-detect";
// import axios from "axios";

const AdminContext = createContext(null);

export const useAdminContext = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
  // ---------------------- states --------------------
  const [adminState, setAdminState] = useState({
    admin: null, // contains the admin token
    adminInfo: null, // contains the admin info object
  });

  // ------------- context --------------
  const { BaseUrl } = useContext(StoreContext);

  // ----------- LOCAL STORAGE ----------
  const LC_TOKEN = localStorage.getItem("ACCESS_TOKEN");

  // ----------------------- functions -------------------------

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  /**
   * Signin context function
   */
  const signIn = async (formData) => {
    try {
      setAdminState({
        ...adminState,
        isLoading: true,
      });
      const { mobile, password } = formData;

      const res = await signInApi({
        BaseUrl: BaseUrl,
        password: password,
        mobile: mobile,
      });
      console.log("signIn", res);
      if (res.isSuccess) {
        window.toast("Loged in successfully");
        localStorage.setItem("ACCESS_TOKEN", res.data);
        localStorage.setItem("ADMIN_INFO", JSON.stringify(res.adminInfo));
        window.location.href = "/?t=" + res.data;
      } else {
        window.toast("Not got the right response, try again", "error");
        setAdminState({
          ...adminState,
          isLoading: false,
        });
      }
    } catch (error) {
      window.toast("Got an error, checkout the console for more information");
      setAdminState({
        ...adminState,
        isLoading: false,
      });

      console.log(error);
    }
  };

  return (
    <AdminContext.Provider
      value={{
        adminState,
        setAdminState,
        signIn,
        LC_TOKEN,
        logout,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;

import { update_santa_by_query_obj } from "../../api/santaApi";

export const dbUpdate = async ({
  collection,
  query = null,
  updateObj = {},
  onInitiate = () => {},
  tags = [],
  onError = () => {},
  onSuccess = () => {},
}) => {
  try {
    onInitiate();
    if (query === null) {
      onError("Query is required");
      return;
    }

    if (collection === "santa") {
      const res = await update_santa_by_query_obj({
        queryObject: query,
        updateObject: updateObj,
        tags: tags,
      });
      if (res.isSuccess) {
        onSuccess(res);
      } else {
        onError(res?.message || "Something went wrong");
      }
    }
  } catch (error) {
    onError(error?.message);
    console.error(error);
  }
};

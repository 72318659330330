import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";
import { memo } from "react";

ChartJS.register(
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

/**
 * @param {data, title}
 * @returns {Bar Chart Group 2 Component}
 * 
 * @memberof ChartJS  
 * 
 * @example
 * data : {
      labels: data?.map(({ _id }) =>
        moment(_id).format(
          type === FORMAT_DAY_WISE ? "DD MMM YY" : "MMM YY"
        )
      ),
      datasets: [
        {
          label: "Order",
          data: data?.map((obj) => obj?.total || 0),
          borderColor: "#f1f0f4",
          backgroundColor: "#7B61FF",
        }, 
      ],
    }
 */
export const BarChart1 = memo(({ data, title }) => {
  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            if (Number.isInteger(value)) {
              if (value === 0) {
                return value;
              } else if (Number.isInteger(value / 1000000)) {
                return value / 1000000 + "M";
              } else if (Number.isInteger(value / 1000)) {
                return value / 1000 + "K";
              } else {
                return value;
              }
            }
            return null;
          },
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
      responsive: true,
    },
  };
  return <Bar data={data} options={options} />;
});

/**
 * @param {data, title}
 * @returns {Bar Chart Group 2 Component}
 * 
 * data : {
      labels: pageData?.orderInfo?.map(({ date }) =>
        moment(date).format("DD MMM YY")
      ),
      datasets: [
        {
          label: "Placed",
          data: pageData?.orderInfo?.map(({ orderCount }) => orderCount),
          borderColor: "#FF0000",
          backgroundColor: "#7B61FF",
        },
        {
          label: "Delivery",
          data: pageData?.orderInfo?.map(({ deliveryCount }) => deliveryCount),
          borderColor: "#f1f0f4",
          backgroundColor: "#FF00011",
        },
      ],
    };
 * 
 */
export const BarChart2 = memo(({ data, title }) => {
  const options = {
    scales: {
      y: {
        min: 0,
        ticks: {
          callback: function (value) {
            if (Number.isInteger(value)) {
              if (value === 0) {
                return value;
              } else if (Number.isInteger(value / 1000000)) {
                return value / 1000000 + "M";
              } else if (Number.isInteger(value / 1000)) {
                return value / 1000 + "K";
              } else {
                return value;
              }
            }
            return null;
          },
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
      responsive: true,
    },
  };

  return <Bar data={data} options={options} />;
});

/**
 * @param {data, title}
 * @returns {Stacked Bar Chart Component}
 * 
 * example for data object : {
      labels: data?.map(({ _id }) =>
        moment(_id).format(
          type === FORMAT_DAY_WISE ? "DD MMM YY" : "MMM YY"
        )
      ),
      datasets: [
        {
          label: 'Completed Orders',
          data: data?.map((obj) => obj?.completed_orders || 0),
          backgroundColor: '#fecaca',
        },
        {
          label: 'Ongoing Orders',
          data: data?.map((obj) => obj?.ongoing_orders || 0),
          backgroundColor: '#bbf7d0',
        },
        {
          label: 'Remaining Orders',
          data: data?.map((obj) => obj?.remaining_orders || 0),
          backgroundColor: '#bfdbfe',
        },
      ],
    }
 */
export const StackedBarChart = memo(({ data, title }) => {
  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) {
            if (Number.isInteger(value)) {
              if (value === 0) {
                return value;
              } else if (Number.isInteger(value / 1000000)) {
                return value / 1000000 + "M";
              } else if (Number.isInteger(value / 1000)) {
                return value / 1000 + "K";
              } else {
                return value;
              }
            }
            return null;
          },
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
      responsive: true,
    },
  };
  return <Bar data={data} options={options} />;
});

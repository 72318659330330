import React, { useEffect, useMemo, useState } from "react";
import { useStoreContext } from "../../../../context/StoreContext";
import { MdDelete, MdEdit } from "react-icons/md";
import ProductCard from "./ProductCard";
import { FaBolt, FaTag } from "react-icons/fa";
import { calculate_offer_discount } from "../../../../functions/calculateLowesPrice";
import AddProduct from "./AddProduct";
import AddonsSelection from "./AddonsSelection";
import FreebieSelection from "./FreebieSelection";

let timeoutId;
export default function ProductSelection({
  states,
  setStates,
  orderStates,
  setOrderStates,
}) {
  const {
    storeStates: { decorCategoryList },
    setStoreStates,
  } = useStoreContext();

  const [pageData, setPageData] = useState({
    creatingNewCustomDecor: false,
  });

  const [isAddProductForm, setIsAddProductForm] = useState(false);

  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("all"); // Default search field
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");

  // Debounce function
  const debounce = (func, delay = 800) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    fetchProducts("", 4);

    // eslint-disable-next-line
  }, []);

  // Fetch products function
  const fetchProducts = (text = searchQuery, decorCategory = category) => {
    // Build filter based on text and category
    const filter = {
      ...(text
        ? {
            $or: [
              searchField === "id" && { _id: { $regex: text, $options: "i" } },
              searchField === "name" && {
                name: { $regex: text, $options: "i" },
              },
              searchField === "all" && {
                $or: [
                  { _id: { $regex: text, $options: "i" } },
                  { name: { $regex: text, $options: "i" } },
                ],
              },
            ].filter(Boolean),
          }
        : {}),
      ...(decorCategory ? { ocassionCategory: decorCategory } : {}),
      active: true,
    };

    window.v2Crud({
      queries: [
        {
          modelName: "Product",
          operation: "read",
          sort: { ocassionCategoryIndex: 1 },
          limit: 50,
          filter,
          selects: [
            "_id",
            "name",
            "ocassionCategory",
            "createdAt",
            "listingPriceHubWise",
            "ocassionCategoryIndex",
            "rawMaterials",
            "description",
            "includes",
            "excludes",
            "about",
            "tags",
            "images",
          ],
          populates: ["rawMaterials.item"],
        },
      ],
      onInitiate: () => setLoading(true),
      onSuccess: (res) => {
        setProducts(res?.data?.at(0)?.data || []);
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      },
    });
  };

  // Debounced search function
  const debouncedSearch = debounce((text, decorCategory) =>
    fetchProducts(text, decorCategory)
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const text = e.target.value;
    setSearchQuery(text);
    setLoading(true);
    debouncedSearch(text);
  };

  if (orderStates.product) {
    return (
      <SelectedProductWork
        {...{ states, setStates, orderStates, setOrderStates }}
      />
    );
  }

  return (
    <div className="flex-col space-y-4 w-full p-4">
      {isAddProductForm && (
        <AddProduct
          setIsAddProductForm={setIsAddProductForm}
          setPageData={setPageData}
          pageData={pageData}
          setMainFormData={setOrderStates}
          mainFormData={orderStates}
        />
      )}
      {/* Search Bar with Dropdown */}
      <div className="flex items-center space-x-2">
        <input
          type="text"
          placeholder="Search products"
          className="flex-grow p-2 border border-gray-300 rounded-md"
          value={searchQuery}
          onChange={handleSearchChange}
        />

        {/* Dropdown for search field selection */}
        <select
          className="p-2 border border-gray-300 rounded-md"
          value={searchField}
          onChange={(e) => setSearchField(e.target.value)}
        >
          <option value="all">All</option>
          <option value="id">ID</option>
          <option value="name">Name</option>
        </select>
      </div>
      <div className="flex items-center justify-between w-full mt-2">
        <select
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
            setLoading(true);
            debouncedSearch(searchQuery, e.target.value);
          }}
          className="p-1 px-2 capitalize bg-blue-50 rounded-lg text-xs md:text-sm border border-blue-300"
        >
          <option value="">All categories</option>
          {/* options of the categories and value selection of code */}
          {decorCategoryList.map((category) => (
            <option key={category.code} value={category.code}>
              {category.categoryName}
            </option>
          ))}
        </select>
        <div className=" flex items-center text-2xs md:text-sm text-[gray] ">
          <p className="text-[gray] ">Edit discount</p>

          <MdEdit
            onClick={() => {
              setStoreStates((p) => ({
                ...p,
                defaultDecorDiscount: {
                  ...p.defaultDecorDiscount,
                  isEnableDiscountEditModal: true,
                },
              }));
            }}
            className=" text-[1rem] text-[#2136d4] cursor-pointer ml-1 "
          />
        </div>

        <button
          className="blue-gradient ml-2 px-3 py-1  text-sm rounded-full "
          onClick={() => {
            setIsAddProductForm(true);
          }}
        >
          Custom product
        </button>
      </div>

      {/* Product Results or Skeleton Loader */}
      <div className="gap-y-4  gap-x-4 flex mt-2 flex-wrap justify-between">
        {loading
          ? Array(15)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="p-3 bg-gray-200 rounded-lg skeleton w-[11rem] flex-shrink-0 aspect-[1/1.2]"
                ></div>
              ))
          : products.map((product) => (
              <div key={product._id}>
                <ProductCard
                  product={product}
                  hubId={orderStates.hubId}
                  setOrderStates={setOrderStates}
                />
              </div>
            ))}
      </div>
    </div>
  );
}

const SelectedProductWork = ({
  states,
  setStates,
  orderStates,
  setOrderStates,
}) => {
  const {
    storeStates: { defaultDecorDiscount },
  } = useStoreContext();

  const { setStoreStates } = useStoreContext();

  const [pageData, setPageData] = useState({
    creatingNewCustomDecor: false,
  });

  const [isAddProductForm, setIsAddProductForm] = useState(false);

  const discount = useMemo(() => {
    // the discount for the decor applied by universal state
    return calculate_offer_discount(orderStates.product?.sellingPrice, {
      defaultDecorDiscount,
    });
  }, [orderStates.product?.sellingPrice, defaultDecorDiscount]);

  return (
    <div className="w-full flex-col space-y-4 ">
      {isAddProductForm && (
        <AddProduct
          setIsAddProductForm={setIsAddProductForm}
          setPageData={setPageData}
          pageData={pageData}
          setMainFormData={setOrderStates}
          mainFormData={orderStates}
        />
      )}
      <div className="w-full rounded-lg border border-gray-200 bg-[white] flex flex-col mt-2 items-center p-2 gap-2 relative">
        <div className="font-[600] flex items-center border-b pb-2 justify-between w-full">
          <span className="text-[1.125rem]">Selected Decor details</span>
          <div className="flex items-center gap-2">
            <div className=" flex items-center mr-2 text-2xs md:text-sm text-[gray] ">
              <p className="text-[gray] ">Edit discount</p>

              <MdEdit
                onClick={() => {
                  setStoreStates((p) => ({
                    ...p,
                    defaultDecorDiscount: {
                      ...p.defaultDecorDiscount,
                      isEnableDiscountEditModal: true,
                    },
                  }));
                }}
                className=" text-[1rem] text-[#2136d4] cursor-pointer ml-1 "
              />
            </div>
            <span
              onClick={() => {
                setOrderStates((p) => ({ ...p, product: null }));
              }}
              className="flex gap-2 border text-sm text-red-600 rounded-lg bg-red-100 py-1 px-2  cursor-pointer items-center"
            >
              Remove <MdDelete />
            </span>{" "}
            <span
              onClick={() => {
                setIsAddProductForm(true);
              }}
              className="flex gap-2 border text-[.825rem] text-[#2136d4] rounded-lg bg-[#ebf3f5] py-1 px-2  cursor-pointer items-center"
            >
              Edit <MdEdit />
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col">
            <div className="font-[500]">{orderStates.product?.name}</div>
            <div className="flex items-center gap-2 text-[.725rem]">
              <div className="font-[500] text-[.825rem]">
                ₹{orderStates.product?.sellingPrice}
              </div>
              <div className="text-[grey] line-through">
                ₹{orderStates.product?.costPrice}
              </div>
              <div className="text-[green]">
                {orderStates.product?.webDiscount}%
              </div>
            </div>

            <div className="mirror-Animation before:w-[20px] bg-gradient-to-r  text-xs  from-blue-50 to-white rounded-[15px] p-1 px-2 font-[400] mt-2 relative right-[4px] flex items-center gap-1  text-[.75rem] text-[#2136d4]  md:text-[.825rem]">
              <FaBolt className="text-[#2136d4] text-[.725rem]  animate-offer-icon " />
              <span className="  font-[600] ">
                ₹{orderStates.product?.sellingPrice - discount}
              </span>
              <FaTag className="text-yellow-500 ml-2" />
              <span className="font-semibold text-yellow-700 mr-2">
                ₹{discount}
              </span>
              After extra discount
            </div>
          </div>
          <div className="flex gap-4 flex-nowrap overflow-scroll mt-3 max-w-full ">
            {orderStates.product?.images?.map?.((curr, idx) => {
              return (
                <div
                  key={`${curr}_${idx}`}
                  className="relative rounded-md overflow-hidden h-auto w-[6rem] min-w-[6rem] flex"
                >
                  <img src={curr} alt="decor" className="w-full" />
                </div>
              );
            })}
            {orderStates.decorImages.map((curr, idx) => {
              return (
                <div
                  key={idx}
                  className="relative rounded-md overflow-hidden h-auto w-[6rem] min-w-[6rem] flex"
                >
                  <img src={curr} alt="decor" className="w-full" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <AddonsSelection
        {...{ states, setStates, orderStates, setOrderStates }}
      />
      <FreebieSelection {...{ orderStates, setOrderStates }} />
    </div>
  );
};

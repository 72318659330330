import { useContext } from "react";
import { motion } from "framer-motion";
import ModalContext from "../../../context/ModalContext";

export default function CustomPopup() {
  const { states, setStates } = useContext(ModalContext);

  return (
    <motion.div
      initial={{ translateY: "100%" }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ translateY: "100%" }}
      transition={{ duration: 0.3 }}
      className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center"
    >
      <div
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 z-10"
        onClick={() =>
          setStates((p) => ({
            ...p,
            customPopup: {
              isRender: false,
              content: "",
            },
          }))
        }
      />
      <div className=" z-20  top-0 bottom-0 left-0 right-0 max-w-[100vw] max-h-[100vh] rounded-[.5rem]  overflow-scroll">
        {states.customPopup.content}
      </div>
    </motion.div>
  );
}

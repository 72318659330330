import React from "react";
import {
  MdCategory,
  MdOutlineCelebration,
  MdOutlineWarehouse,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { RiTeamFill } from "react-icons/ri";
import {
  BiCalendar,
  BiError,
  BiLocationPlus,
  BiSolidBellRing,
  BiSolidCategory,
  BiSolidOffer,
  BiSolidUser,
} from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { AnimatePresence, motion } from "framer-motion";
import { FaCartArrowDown, FaRegUser, FaTable } from "react-icons/fa";
import { GrVmware } from "react-icons/gr";
import { GoChecklist } from "react-icons/go";
import { TbCheckupList, TbDeviceAnalytics } from "react-icons/tb";
import { RiSeoLine } from "react-icons/ri";
import { IoMdCall } from "react-icons/io";
import Sidebar from "../components/Sidebar";
import { BsFillBox2HeartFill } from "react-icons/bs";
import { VscFeedback } from "react-icons/vsc";

export default function MoreNavOptionsModal({
  setShowMoreNavOptions,
  showMoreNavOptions,
}) {
  return (
    <AnimatePresence>
      {showMoreNavOptions && (
        <Modal setShowMoreNavOptions={setShowMoreNavOptions} />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ setShowMoreNavOptions }) => {
  const handleClose = () => setShowMoreNavOptions(false);

  return (
    <div
      className="fixed top-0 right-0 left-0   bottom-0
       flex items-end justify-end z-[999] text-black "
    >
      <div
        onClick={handleClose}
        className="partial-black-background bg-[#00000000]"
      ></div>
      <motion.div
        initial={{
          translateX: 800,
        }}
        animate={{
          translateX: 0,
        }}
        exit={{
          translateX: 800,
        }}
        transition={{ ease: "easeInOut" }}
        className=" w-full md:w-[30rem] h-[100%]
       flex items-center  overflow-hidden
        drop-shadow border-t px-4 bg-[#ffffff] z-[2] text-[.875rem] flex-col "
      >
        <div className="w-full font-[500] flex items-center justify-between text-[1rem] mb-2 py-2 ">
          <div
            onClick={handleClose}
            className="text-[#2136d4] cursor-pointer text-[1.25rem] "
          >
            <RxCross2 />
          </div>
          <div className=" flex flex-col justify-center items-center ">
            <div className="text-[1.125rem] font-[500] ">Quick Links</div>
          </div>
        </div>

        <div className="rounded-t-xl overflow-scroll gap-3 p-3  relative flex flex-col bg-[#f5f5f5] w-full h-full ">
          <div className="flex whitespace-nowrap flex-wrap justify-between w-full font-[500] cursor-pointer ">
            {navigationOptionsArray.map((curr, idx) => {
              return (
                <Link
                  key={idx}
                  to={curr.navigateTo}
                  onClick={() => {
                    handleClose();
                  }}
                  className="flex border bg-white shadow-md mb-4 border-gray-200 text-[1.25rem] py-2 w-[45%] px-4 hover:blue-gradient items-center gap-2 rounded-md "
                >
                  {curr.icon}
                  <span className="font-[400] text-[10px] ">{curr.label}</span>
                </Link>
              );
            })}

            <Sidebar placement={"inNavbar"} />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const navigationOptionsArray = [
  {
    label: "Hubs",
    navigateTo: "/hub",
    icon: <GrVmware />,
  },
  {
    label: "Inventory",
    navigateTo: "/inventory/city",
    icon: <MdOutlineWarehouse />,
  },
  {
    label: "Locations",
    navigateTo: "/location",
    icon: <BiLocationPlus />,
  },
  {
    label: "Master sheet",
    navigateTo: "/inventory/mastersheet/",
    icon: <GoChecklist />,
  },
  {
    label: "Orders",
    navigateTo: "/order",
    icon: <BsFillBox2HeartFill />,
  },
  {
    label: "Products",
    navigateTo: "/product",
    icon: <TbCheckupList />,
  },
  {
    label: "Santa",
    navigateTo: "/team/santa",
    icon: <RiTeamFill />,
  },
  {
    label: "Banners",
    navigateTo: "/ebo/banners",
    icon: <MdCategory />,
  },
  {
    label: "ebo",
    navigateTo: "/ebo",
    icon: <MdCategory />,
  },
  {
    label: "Place order",
    navigateTo: "/order/manual-order-place",
    icon: <FaCartArrowDown />,
  },
  {
    label: "Users",
    navigateTo: "/user",
    icon: <FaRegUser />,
  },
  {
    label: "Traffic",
    navigateTo: "/traffic",
    icon: <TbDeviceAnalytics />,
  },
  {
    label: "Coupons",
    navigateTo: "/Admin/Coupans",
    icon: <BiSolidOffer />,
  },
  {
    label: "Categories",
    navigateTo: "/decorCategory",
    icon: <BiSolidCategory />,
  },
  {
    label: "e-calendar",
    navigateTo: "/ebo-calendar",
    icon: <BiCalendar />,
  },
  {
    label: "SEO",
    navigateTo: "/seo",
    icon: <RiSeoLine />,
  },
  {
    label: "Admins",
    navigateTo: "/admin",
    icon: <BiSolidUser />,
  },
  {
    label: "Call logs",
    navigateTo: "/call-logs",
    icon: <IoMdCall />,
  },
  {
    label: "Triggers",
    navigateTo: "/triggers",
    icon: <BiSolidBellRing />,
  },
  {
    label: "Upcoming Celebrations",
    navigateTo: "/Admin/Celebration",
    icon: <MdOutlineCelebration />,
  },
  {
    label: "Table View",
    navigateTo: "/tableView",
    icon: <FaTable />,
  },
  {
    label: "Error Logs",
    navigateTo: "/errors",
    icon: <BiError />,
  },
  {
    label: "Feedback",
    navigateTo: "/feedback?activeTab=order_feedback",
    icon: <VscFeedback />,
  },
];

import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { LineChart1, PieChart1, StackedBarChart } from "../components/charts";
import { RxCross2 } from "react-icons/rx";
import { AppleMaps } from "./location/AppleMaps";

export default function ViewChartModal({ parentStates, setParentStates }) {
  return (
    <AnimatePresence>
      {parentStates.viewChartData.isRender && (
        <Modal
          type={parentStates.viewChartData.type}
          title={parentStates.viewChartData.title}
          data={parentStates.viewChartData.data}
          setParentStates={setParentStates}
        />
      )}
    </AnimatePresence>
  );
}

function Modal({ type, setParentStates, data, title }) {
  function handleClose() {
    setParentStates((p) => ({
      ...p,
      viewChartData: {
        isRender: false,
        type: "",
        data: null,
      },
    }));
  }

  if (type === "pointsMap") {
    return (
      <div className="fixed right-0 top-0 left-0 bottom-0 flex justify-center items-center z-[999]">
        {/* background partial black layout */}
        <div
          onClick={handleClose}
          className="fixed right-0 top-0 left-0 bottom-0 z-[1] bg-[#00000092]"
        ></div>
        <motion.div
          initial={{ translateX: 500, opacity: 0 }}
          animate={{ translateX: 0, opacity: 1 }}
          exit={{ translateX: 500, opacity: 0 }}
          transition={{ duration: 0.2, ease: "linear" }}
          className=" w-full md:w-[90%]  h-[90%] max-h-[95%] text-[.875rem] relative z-[2] flex flex-col items-center p-4 rounded-lg bg-[white]  "
        >
          <div className="w-full flex justify-between items-center">
            <div className="text-[1.125rem] md:text-[1.25rem] font-[600] ">
              Service points
            </div>
            <RxCross2
              onClick={handleClose}
              className="cursor-pointer text-[1.25rem] md:text-[2rem] "
            />
          </div>
          <div className="p-4 w-full bg-white flex flex-col h-full ">
            {/* advance marker points with some points of mumbai */}
            <AppleMaps points={data} />
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 flex justify-center items-center z-[999]">
      {/* background partial black layout */}
      <div
        onClick={handleClose}
        className="fixed right-0 top-0 left-0 bottom-0 z-[1] bg-[#00000092]"
      ></div>
      <motion.div
        initial={{ translateX: 500, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: 500, opacity: 0 }}
        transition={{ duration: 0.2, ease: "linear" }}
        className=" w-full md:w-[90%]  h-auto max-h-[95%] text-[.875rem] relative z-[2] flex flex-col items-center p-4 rounded-lg bg-[white]  "
      >
        <div className=" absolute top-4  text-[1.25rem]  right-4 cursor-pointer md:text-[2rem] ">
          <RxCross2 onClick={handleClose} />
        </div>
        {type === "bar" && (
          <StackedBarChart data={data} title={title} />
        )}
        {type === "pie" && (
          <PieChart1 data={data} title={title} />
        )}
        {type === "line" && (
          <LineChart1 data={data} title={title} />
        )}
      </motion.div>
    </div>
  );
}

import React, { useState, memo, useEffect, useMemo } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import ProductForm from "./ProductForm";
import { motion } from "framer-motion";
import { FaBolt, FaTag } from "react-icons/fa";
import { calculate_offer_discount } from "../../../../functions/calculateLowesPrice";
import { useStoreContext } from "../../../../context/StoreContext";

function AddProduct({
  setIsAddProductForm,
  setMainFormData,
  setPageData,
  pageData,
  mainFormData,
}) {
  const [formData, setFormData] = useState({
    _id: "",
    name: "",
    images: [],
    costPrice: "",
    sellingPrice: "",
    webDiscount: 35,
    ocassionCategory: "",
  });

  const {
    storeStates: { defaultDecorDiscount },
  } = useStoreContext();

  const [decorImages, setDecorImages] = useState([]);
  const [productSpinner, setProductSpinner] = useState(false);
  useEffect(() => {
    if (mainFormData?.product?.name && !pageData.creatingNewCustomDecor) {
      const { product } = mainFormData;
      setFormData({
        _id: product._id,
        name: product.name,
        images: product.images,
        costPrice: product.costPrice,
        sellingPrice: product.sellingPrice,
        webDiscount: product.webDiscount || 35,
        ocassionCategory: product.ocassionCategory,
      });
      setDecorImages(mainFormData.decorImages);
    }
    // eslint-disable-next-line
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (formData.sellingPrice && formData.webDiscount) {
      const costPrice = Math.round(
        (formData.sellingPrice * 100) / (100 - formData.webDiscount)
      );
      setFormData((prevState) => ({ ...prevState, costPrice }));
    }
  }, [formData.sellingPrice, formData.webDiscount]);

  const handleProductPageClose = () => {
    setIsAddProductForm(false);
    setPageData((prev) => ({ ...prev, creatingNewCustomDecor: false }));
  };

  const handleSubmitbtn = async (e) => {
    setProductSpinner(true);
    try {
      e.preventDefault(); // Prevent form submission reloading the page
      if (
        !formData.name ||
        !formData.sellingPrice ||
        !formData.costPrice ||
        (!decorImages?.length === 0 && !mainFormData?.product) ||
        formData.ocassionCategory === ""
      ) {
        window.toast("Please fill all the fields", "error");
        setProductSpinner(false);
        return;
      }

      if (decorImages.length === 0 && !mainFormData?.product) {
        window.toast("Please upload images", "error");
        setProductSpinner(false);
        return;
      }

      if (formData.sellingPrice > formData.costPrice) {
        window.toast(
          "Cost price should be greater than selling price",
          "error"
        );
        setProductSpinner(false);
        return;
      }
      if (formData.webDiscount > 100) {
        window.toast("Web discount should be less than 100", "error");
        setProductSpinner(false);
        return;
      }
      if (
        typeof parseInt(formData.sellingPrice) !== "number" ||
        typeof parseInt(formData.costPrice) !== "number"
      ) {
        window.toast("Please enter valid numbers", "error");
        setProductSpinner(false);
        return;
      }
      const product = {
        ...formData,
        sellingPrice: parseInt(formData.sellingPrice),
        costPrice: parseInt(formData.costPrice),
      };

      setMainFormData((prev) => {
        return { ...prev, product: product, decorImages: decorImages };
      });
    } catch (error) {
      toast.error(`Error! ${error.message}`);
    }
    setProductSpinner(false);
    handleProductPageClose();
  };

  const discount = useMemo(() => {
    // the discount for the decor applied by universal state
    return calculate_offer_discount(formData?.sellingPrice, {
      defaultDecorDiscount,
    });
  }, [formData?.sellingPrice, defaultDecorDiscount]);

  return (
    <div className="fix-screen flex-center  z-[16]">
      <div
        onClick={handleProductPageClose}
        className="fixed z-[2] top-0 bottom-0 w-full bg-[#000000bb]"
      ></div>

      <motion.div
        initial={{ opacity: 0, translateY: 200 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ ease: "easeIn" }}
        className=" max-w-[95vw]  bg-[white] max-h-[90%] overflow-scroll rounded-lg p-4 shadow-lg z-[3]"
      >
        <div className=" flex gap-2 font-[600] items-center mb-2  text-[1.5rem] text-[#171717]">
          <RxCross2 onClick={handleProductPageClose} />
          <h2 className="text-[1.25rem] text-[#171717]">
            Custom Product Details
          </h2>
        </div>
        <ProductForm
          formData={formData}
          handleInput={handleInput}
          handleSubmitbtn={handleSubmitbtn}
          setDecorImages={setDecorImages}
          decorImages={decorImages}
          productSpinner={productSpinner}
          setFormData={setFormData}
        />
        <div className="mirror-Animation before:w-[20px] bg-gradient-to-r  text-xs  from-blue-50 to-white rounded-[15px] p-1 px-2 font-[400] mt-2 relative right-[4px] flex items-center gap-1  text-[.75rem] text-[#2136d4]  md:text-[.825rem]">
          <FaBolt className="text-[#2136d4] text-[.725rem]  animate-offer-icon " />
          <span className="  font-[600] ">
            ₹{formData?.sellingPrice - discount}
          </span>
          <FaTag className="text-yellow-500 ml-2" />
          <span className="font-semibold text-yellow-700 mr-2">
            ₹{discount}
          </span>
          After extra discount
        </div>
      </motion.div>
    </div>
  );
}

export default memo(AddProduct);

import axios from "axios";

const BaseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("ACCESS_TOKEN");

const add_address = async ({ data }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/address/add_address`,
      { data },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
};

const get_address_by_query_obj = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/address/get_address_by_query_obj`,
      {
        queryObject,
        skip: skip || 0,
        limit: limit || 25,
        sortingObj: sortingObj || { createdAt: "desc" },
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      isError: true,
      data: null,
      message: error,
    };
  }
};

const update_address_by_query_obj = async ({ queryObject, updateObject }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/address/update_address_by_query_obj`,
      {
        queryObject,
        updateObject,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      isError: true,
      data: null,
      message: error,
    };
  }
};

const remove_address_api = async (addressID) => {
  try {
    const res = await axios.get(
      `${BaseUrl}/api/admin/address/delete-address/${addressID}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

export {
  add_address,
  get_address_by_query_obj,
  update_address_by_query_obj,
  remove_address_api,
};

import React, { useState } from "react";
import { FaRegCalendar } from "react-icons/fa";
import moment from "moment";

export default function TimeSlotSection({ orderStates, setOrderStates }) {
  const [dateRange, setDateRange] = useState({
    label: orderStates?.celebrationDate
      ? moment(orderStates?.celebrationDate).format("Do, MMM YYYY")
      : "Select ",
    from: orderStates?.celebrationDate,
    to: orderStates?.celebrationDate,
  });

  const handleClickOnDay = (idx) => {
    setOrderStates((p) => ({
      ...p,
      celebrationDate: moment().add(idx, "days"),
    }));
  };

  const dateArrayCount = 6;

  return (
    <div className="w-full flex-col">
      <div className="text-base font-semibold mt-2 mb-2">
        Select Celebration date
      </div>
      <div className=" whitespace-nowrap items-center w-full flex gap-[1px] overflow-scroll flex-shrink-0 ">
        {/* other date array */}
        {Array.from({ length: dateArrayCount }).map((curr, idx) => {
          const isSelected =
            orderStates?.celebrationDate &&
            moment()
              .add(idx, "days")
              .isSame(orderStates?.celebrationDate, "days");

          return (
            <div
              onClick={() => {
                handleClickOnDay(idx);
              }}
              className={`border border-[#ffffff] cursor-pointer flex flex-col items-center
         justify-center rounded-lg min-h-[4rem] min-w-[4rem] w-[4rem] h-[4rem] ${
           isSelected ? "blue-gradient" : ""
         }    `}
            >
              {moment().isSame(moment().add(idx, "days"), "days") ? (
                <>
                  <div className="text-[.875rem] font-[500] leading-5 ">
                    Today
                  </div>
                  <div
                    style={{
                      color: isSelected ? "white" : "gray",
                    }}
                    className="text-[.625rem] text-[grey] font-[400]"
                  >
                    {moment().add(idx, "days").format("ddd Do")}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      color: isSelected ? "white" : "gray",
                    }}
                    className="text-[.75rem] text-[grey] font-[400] "
                  >
                    {moment().add(idx, "days").format("MMM")}
                  </div>
                  <div className="text-[.875rem] font-[600] leading-5 ">
                    {moment().add(idx, "days").format("DD")}
                  </div>
                  <div
                    style={{
                      color: isSelected ? "white" : "gray",
                    }}
                    className="text-[.75rem] text-[grey] font-[400]"
                  >
                    {moment().add(idx, "days").format("ddd")}
                  </div>
                </>
              )}
            </div>
          );
        })}

        <div
          onClick={() => {
            window.setDateRange({
              selectors: ["date"],
              callBack: (r) => {
                setDateRange({
                  label: r.label,
                  from: r.from,
                  to: r.to,
                });
                setOrderStates((prev) => ({
                  ...prev,
                  celebrationDate: moment(r.from).toISOString(),
                }));
              },
            });
          }}
          className={` ${
            orderStates?.celebrationDate &&
            moment(orderStates.celebrationDate).isAfter(
              moment().add(dateArrayCount - 1, "days")
            )
              ? "blue-gradient"
              : ""
          } border cursor-pointer border-gray-200 rounded-md flex gap-2 items-center text-sm px-2 py-1 ml-4`}
        >
          {dateRange.label} <FaRegCalendar />
        </div>
      </div>

      <div className="text-base font-semibold mt-4">Select Ready time</div>
      <ReadyTimeBase />
    </div>
  );

  function ReadyTimeBase() {
    const [localStates, setLocalStates] = useState({
      readyTime: "",
      arrivingTime: "",
      lastIdx: null,
      selectedIdx: null,
      isArrivingSlotContainer: true,
    });

    const handleClick = (readyTime) => {
      setOrderStates((prevState) => ({
        ...prevState,
        readyTime: readyTime,
        arrivingTime: moment(readyTime, "hours")
          .subtract(prevState.workHour, "hours")
          .hours(),
      }));
      setLocalStates((p) => ({
        ...p,
        isArrivingSlotContainer: true,
      }));
    };

    const slotsCount = 16;
    const slotStartTime = 8;
    return (
      <>
        <div
          className={` w-full flex justify-between transition-all  flex-wrap gap-y-4 mt-4`}
        >
          {Array.from({ length: slotsCount })?.map((curr, idx) => {
            const readyTimeInHours = idx + slotStartTime;
            const currentTime = moment(readyTimeInHours, "HH");
            const isSelected = orderStates.readyTime === readyTimeInHours;
            const itemsPerRow = 3;
            const lastIdxOfRow =
              Math.floor(idx / itemsPerRow) * itemsPerRow + itemsPerRow - 1;

            if (isSelected && localStates.lastIdx !== lastIdxOfRow) {
              setLocalStates((p) => ({
                ...p,
                lastIdx: lastIdxOfRow,
                selectedIdx: idx,
              }));
            }

            return (
              <>
                <div
                  key={idx}
                  onClick={() => {
                    handleClick(readyTimeInHours);
                  }}
                  className={`${
                    isSelected ? "blue-gradient" : " bg-white"
                  }   w-[31%] h-[2.25rem] font-[500] cursor-pointer text-[.875rem] flex items-center justify-center border rounded-lg border-[#ededed]`}
                >
                  {currentTime.format("hh:00 A")}
                </div>
                {idx === localStates.lastIdx &&
                  localStates.isArrivingSlotContainer && (
                    <ArrivingSlotPicker
                      orderData={orderStates}
                      parentStates={localStates}
                      setOrderStates={setOrderStates}
                    />
                  )}
              </>
            );
          })}
        </div>
      </>
    );
  }
}

function ArrivingSlotPicker({ parentStates, orderData, setOrderStates }) {
  const handleSetArrivingTime = (currentTime) => {
    setOrderStates((prevState) => ({
      ...prevState,
      arrivingTime: currentTime.format("HH"),
    }));
  };

  return (
    <div className="flex flex-col w-full bg-gradient-to-r from-white via-blue-200 to-white px-2 py-3 ">
      <div className="text-sm font-[500]  ">
        ebo Santa will arrive between the selected time
      </div>

      <div
        className={` flex w-full max-w-full mt-2 overflow-x-scroll whitespace-nowrap gap-2 justify-evenly overflow-y-hidden`}
      >
        {Array.from({ length: 3 }).map((curr, idx) => {
          const arrivingSlot = moment(orderData.readyTime, "HH").subtract(
            orderData.workHour * (idx + 1),
            "hours"
          );

          console.log(orderData.workHour * (idx + 1));

          const isSelected =
            moment(orderData.arrivingTime, "HH").hour() === arrivingSlot.hour();

          return (
            <div
              key={idx + "arrivingslots"}
              onClick={() => handleSetArrivingTime(arrivingSlot)}
              className={` h-[2.25rem]  px-3 cursor-pointer text-[.875rem] flex items-center justify-center border rounded-lg ${
                isSelected ? "blue-gradient " : "bg-white"
              }`}
            >
              {arrivingSlot.format("hh:mm A")} -{" "}
              {moment(arrivingSlot).add(1, "hours").format("hh:mm A")}
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import { v2Api } from "../../../api/v2.api";
import AdminContext from "../../../context/AdminContext";
import moment from "moment";

export default function HandleEnquiery({ parentStates, setParentStates }) {
  const handleClose = async () => {
    setParentStates((p) => ({
      ...p,
      enquiry: {
        isRender: false,
        options: null,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates?.enquiry?.isRender && (
        <Modal
          handleClose={handleClose}
          options={parentStates.enquiry.options}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, options }) => {
  const [states, setStates] = useState({
    enquiryId: "",
    phoneNumber: "",
    name: "",
    area: "",
    occasionName: "",
    readyTime: "",
    budget: {
      minPrice: "",
      maxPrice: "",
    },
    trafficSource: "",
    celebrationDate: "",
    agent: "",
    tags: [],
    reminderDate: "",
    scehduledDate: "",
    startingEnquiry: false,
    submitingEnquiry: false,
    enquiryStarted: false,
  });

  useEffect(() => {
    if (options?.isEdit && options?.editData) {
      const editData = options.editData;
      setStates((p) => ({
        ...p,
        enquiryId: editData._id,
        phoneNumber: editData.phoneNumber,
        name: editData.name,
        area: editData.area,
        occasionName: editData.occasionName,
        readyTime: editData.readyTime,
        budget: editData?.budget
          ? editData.budget
          : {
              minPrice: "",
              maxPrice: "",
            },
        trafficSource: editData.trafficSource,
        celebrationDate: editData.celebrationDate
          ? moment(editData.celebrationDate).format("YYYY-MM-DD")
          : "",
        tags: editData.tags,
        reminderDate: editData.reminderDate,
        scehduledDate: editData.scehduledDate
          ? moment(editData.scehduledDate).format("YYYY-MM-DD")
          : "",
        enquiryStarted: true,
      }));
    }
    handleStartNewEnquiry();
    // eslint-disable-next-line
  }, []);

  const handleStartNewEnquiry = async () => {
    try {
      if (!options?.newEnquiryWithExistingUser) {
        return;
      }

      setStates((p) => ({
        ...p,
        startingEnquiry: true,
        phoneNumber: options?.newEnquiryWithExistingUser.phoneNumber,
      }));

      const option = {
        tags: ["enquiry"],
        operation: "create",
        enquiryData: {
          phoneNumber: options?.newEnquiryWithExistingUser.phoneNumber,
        },
      };

      const res = await v2Api(option);

      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          startingEnquiry: false,
          enquiryStarted: true,
          enquiryId: res.data._id,
          name: res.data.name,
        }));
        window.toast("Enquiry created");
      } else {
        setStates((p) => ({ ...p, startingEnquiry: false }));
        window.toast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
      setStates((p) => ({ ...p, startingEnquiry: false }));
    }
  };

  return (
    <div className="fixed top-0 z-[95] flex items-end justify-end md:justify-start right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: "0%", opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-start w-full md:w-[30rem]    flex-col bg-[white] rounded-t-lg md:rounded-tr-lg md:rounded-bl-lg "
      >
        <ModalHeader handleClose={handleClose} />
        <div className=" w-full px-4 my-4 max-h-[75vh]  overflow-scroll flex flex-col  ">
          <PhoneNumberInput states={states} setStates={setStates} />

          {/* <StartBtn states={states} setStates={setStates} /> */}
          {/* Other fields */}
          {/* {states.enquiryStarted && (
          )} */}
          <OtherEnquiryFields states={states} setStates={setStates} />
        </div>
        <SubmitBtn
          states={states}
          setStates={setStates}
          handleClose={handleClose}
          options={options}
        />
        {/* {states.enquiryStarted && (
        )} */}
      </motion.div>
    </div>
  );
};

// start enquiry button to create a new enquiry in the system (adding a new enquiry to the database with givent phone number)
const StartBtn = ({ states, setStates }) => {
  // will asyncronously create a new enquiry in the system ( data base )
  const handleStartEnquiry = async () => {
    try {
      setStates((p) => ({ ...p, startingEnquiry: true }));

      const option = {
        tags: ["enquiry"],
        operation: "create",
        enquiryData: { phoneNumber: states.phoneNumber },
      };

      const res = await v2Api(option);

      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          startingEnquiry: false,
          enquiryStarted: true,
          enquiryId: res.data._id,
          name: res.data.name,
        }));
        window.toast("Enquiry created");
      } else {
        setStates((p) => ({ ...p, startingEnquiry: false }));
        window.toast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
      setStates((p) => ({ ...p, startingEnquiry: false }));
    }
  };

  return (
    <div
      className={`w-full ${
        states.enquiryStarted && "hidden"
      } flex items-start justify-start mb-4`}
    >
      <div
        onClick={handleStartEnquiry}
        className={`rounded-full px-8 py-2 blue-gradient  ${
          states.startingEnquiry && " pointer-events-none "
        } `}
      >
        {states.startingEnquiry ? (
          <div
            style={{ borderTopColor: "white" }}
            className=" loader w-[1rem]"
          ></div>
        ) : (
          "Start"
        )}
      </div>
    </div>
  );
};

const ModalHeader = ({ handleClose }) => {
  return (
    <div className="w-full text-[1rem] border-b border-gray-400 flex justify-between items-center px-4 py-2 ">
      <div className=" scale-[1.1] font-[500]  ">New Enquiry</div>
      <RxCross1 onClick={handleClose} className="text-[1rem]" />
    </div>
  );
};

const PhoneNumberInput = ({ states, setStates }) => {
  return (
    <div className="rounded-lg border relative mb-4 flex items-center w-full border-gray-200   ">
      <input
        className="w-full p-2 rounded-lg"
        type="text"
        inputMode="numeric"
        placeholder="Phone Number"
        value={states.phoneNumber}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            phoneNumber: e.target.value,
          }));
        }}
      />
      <RxCross1
        onClick={() => {
          setStates((p) => ({
            ...p,
            phoneNumber: "",
          }));
        }}
        className={` ${
          (states.phoneNumber === "" || states.enquiryStarted) && "hidden"
        } absolute right-2 text-[1rem]`}
      />
    </div>
  );
};

// other fields: name | area | occasionName | notes (text area) | budget {minPrice , maxPrice} (range) | trafficSource | celebrationDate | readyTime (select field - 12am to 12pm) | tags (comma seperated strings) -> ( array - while uploadin) | reminderDate (date -> default date selection input)
const OtherEnquiryFields = ({ states, setStates }) => {
  return (
    <div className="w-full flex flex-col gap-2 ">
      <input
        className="p-2 border rounded-lg"
        type="text"
        placeholder="Name"
        value={states.name}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            name: e.target.value,
          }));
        }}
      />
      <input
        className="p-2 border rounded-lg"
        type="text"
        placeholder="Area"
        value={states.area}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            area: e.target.value,
          }));
        }}
      />
      <div className=" mt-2 text-gray-300 text-[.75rem] -mb-1 pl-1 ">
        Celebration date and time
      </div>
      <div className="flex gap-2 w-full">
        <input
          className="p-2 border rounded-lg w-[50%]"
          type="date"
          placeholder="Celebration Date"
          value={states.celebrationDate}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              celebrationDate: e.target.value,
            }));
          }}
        />
        <select
          className="p-2 border rounded-lg w-[50%]"
          name="readyTime"
          id="readyTime"
          placeholder="Ready Time"
          value={states.readyTime}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              readyTime: e.target.value,
            }));
          }}
        >
          <option value="">Select Ready Time</option>
          {Array.from({ length: 24 }, (_, i) => (
            <option value={i} key={i}>
              {i === 0
                ? "12am"
                : i === 12
                ? "12pm"
                : i < 12
                ? `${i}am`
                : `${i - 12}PM`}
            </option>
          ))}
        </select>
      </div>
      <div className=" mt-2 text-gray-300 text-[.75rem] -mb-1 pl-1 ">
        Occasion Name
      </div>
      <input
        className="p-2 border rounded-lg"
        type="text"
        placeholder="Occasion Name"
        value={states.occasionName}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            occasionName: e.target.value,
          }));
        }}
      />
      <div className=" mt-2 text-gray-300 text-[.75rem] -mb-1 pl-1 ">
        Budget (₹)
      </div>
      <div className="flex gap-2 w-full">
        <input
          className="p-2 border rounded-lg w-[50%]"
          type="number"
          placeholder="Min budget"
          value={states.budget.minPrice}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              budget: {
                ...p.budget,
                minPrice: e.target.value,
              },
            }));
          }}
        />
        <input
          className="p-2 border rounded-lg w-[50%]"
          type="number"
          placeholder="Max budget"
          value={states.budget.maxPrice}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              budget: {
                ...p.budget,
                maxPrice: e.target.value,
              },
            }));
          }}
        />
      </div>
      <input
        className="p-2 border rounded-lg"
        type="text"
        placeholder="Tags (comma seperated)"
        value={states.tags.join(",")}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            tags: e.target.value.split(","),
          }));
        }}
      />
      <input
        className="p-2 border rounded-lg"
        type="text"
        placeholder="Traffic Source"
        value={states.trafficSource}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            trafficSource: e.target.value,
          }));
        }}
      />
      <div className=" mt-2 text-gray-300 text-[.75rem] -mb-1 pl-1 ">
        Scehduled enquiry date (required for scheduled status)
      </div>
      <input
        className="p-2 border rounded-lg w-[50%]"
        type="date"
        placeholder="Scehduled enquiry Date"
        value={states.scehduledDate}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            scehduledDate: e.target.value,
          }));
        }}
      />
    </div>
  );
};

const SubmitBtn = ({ states, setStates, handleClose, options }) => {
  const { adminState } = useContext(AdminContext);

  const handleSubmitReasonSelection = () => {
    window.askRadio(
      "Select enquery status",
      "",
      ["Still open", "Dropped", "Converted", "Scheduled"],
      "Submit",
      (r) => {
        if (r === "Still open") {
          handleSubmit("open");
        }
        if (r === "Dropped") {
          window.ask(
            "Reason for dropping",
            "Please provide a reason for dropping the enquiry",
            ["Reason"],
            "Submit",
            (r) => {
              handleSubmit("dropped", r[0]);
            }
          );
        }
        if (r === "Converted") {
          handleSubmit("converted");
        }
        if (r === "Scheduled") {
          window.warningModal({
            msg: "Have you scheduled the enquiry?",
            rb_text: "Yes",
            lb_text: "No",
            rb_fn: () => {
              handleSubmit("scheduled");
            },
            lb_fn: () => {
              window.toast("Please schedule the enquiry first");
            },
          });
        }
      },
      false
    );
  };

  const handleSubmit = async (enquiryStatus, remark) => {
    try {
      setStates((p) => ({ ...p, submitingEnquiry: true }));
      const option = {
        tags: ["enquiry"],
        operation: "update",
        enquiryId: states.enquiryId,
        enquiryData: {
          phoneNumber: states.phoneNumber,
          enquiryStatus,
          remark,
          name: states.name,
          area: states.area,
          agent: adminState.adminInfo?._id,
          occasionName: states.occasionName,
          readyTime: states.readyTime,
          budget: states.budget,
          trafficSource: states.trafficSource,
          celebrationDate: states.celebrationDate
            ? moment(states.celebrationDate).toDate()
            : "",
          tags: states.tags,
          reminderDate: states.reminderDate,
          scehduledDate: states.scehduledDate,
        },
      };

      const res = await v2Api(option);
      if (res.isSuccess) {
        window.toast("Enquiry updated");
        handleClose();
        options?.callBackFun && options.callBackFun();
      } else {
        window.toast(res.message, "error");
      }

      setStates((p) => ({ ...p, submitingEnquiry: false }));
    } catch (error) {
      console.log(error);
      setStates((p) => ({ ...p, submitingEnquiry: false }));
    }
  };

  return (
    <div className="w-full flex items-start justify-start p-2">
      <div
        onClick={handleSubmitReasonSelection}
        className={`rounded-lg px-8 w-full text-center py-2 blue-gradient ${
          states.submitingEnquiry && " pointer-events-none "
        } `}
      >
        {states.submitingEnquiry ? (
          <div
            style={{ borderTopColor: "white" }}
            className=" loader w-[1rem]"
          ></div>
        ) : (
          "Submit"
        )}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";

// Debounce function to reduce API calls
let timeoutId;
export default function FreebieSelection({ orderStates, setOrderStates }) {
  const [searchText, setSearchText] = useState("");
  const [freebies, setFreebies] = useState([]);
  const [loading, setLoading] = useState(false);

  const debounce = (func, delay = 800) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    fetchFreebies("");

    // eslint-disable-next-line
  }, []);

  // Fetch freebies based on searchText using v2Crud
  const fetchFreebies = (query) => {
    window.v2Crud({
      queries: [
        {
          modelName: "Freebie",
          operation: "read",
          filter: {
            $or: [
              { name: { $regex: query, $options: "i" } },
              { _id: { $regex: query, $options: "i" } },
            ],
            isActive: true,
          },
          limit: 30,
          sort: { createdAt: -1 },
        },
      ],
      onInitiate: () => setLoading(true),
      onSuccess: (res) => {
        setFreebies(res?.data?.at(0)?.data || []);
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching freebies:", error);
        setLoading(false);
      },
    });
  };

  const debouncedFetchFreebies = debounce(fetchFreebies);

  // Handle search input change with debouncing
  const handleSearchChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    setLoading(true);
    debouncedFetchFreebies(text);
  };

  // Select a freebie and add it to the order
  const handleFreebieSelect = (freebie) => {
    setOrderStates((prev) => ({
      ...prev,
      freebie: freebie,
    }));
    setSearchText("");
  };

  // Remove the selected freebie
  const handleFreebieRemove = () => {
    setOrderStates((prev) => ({
      ...prev,
      freebie: null,
    }));
  };

  return (
    <div className="w-full bg-white p-4 border rounded-md shadow-sm">
      <div className="font-semibold text-base gap-2 flex items-center">
        {orderStates.freebie ? (
          <>
            Freebie selected{" "}
            <BsFillCheckCircleFill className="text-green-600 text-xl" />
          </>
        ) : (
          "Freebies"
        )}
      </div>

      {/* Show selected freebie if present, otherwise show search and list */}
      {orderStates.freebie ? (
        <div className="mt-4 border p-3 w-[10rem] rounded-lg flex flex-col items-center relative">
          <img
            src={orderStates.freebie.image}
            alt={orderStates.freebie.name}
            className="w-full h-32 object-cover rounded-md mb-2"
          />
          <p className="text-sm font-semibold text-center">
            {orderStates.freebie.name}
          </p>
          <div className="flex items-center gap-1 mt-1">
            <div className="text-gray-500 text-xs line-through">
              ₹{orderStates.freebie.price}
            </div>
            <div className="text-green-500 font-bold text-sm">FREE</div>
          </div>
          <AiFillDelete
            onClick={handleFreebieRemove}
            className="text-red-600 cursor-pointer text-xl absolute top-2 right-2"
            title="Remove Freebie"
          />
        </div>
      ) : (
        <>
          {/* Freebie Search Input */}
          <div className="flex flex-col mb-4 mt-2">
            <input
              type="text"
              value={searchText}
              onChange={handleSearchChange}
              placeholder="Search by Freebie Name or ID"
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>

          {/* Freebie List or Loading State */}
          <div className="gap-y-4 gap-x-4 flex mt-2 flex-wrap justify-between">
            {loading
              ? Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="p-3 bg-gray-200 rounded-lg skeleton w-[10rem] flex-shrink-0 aspect-[1/1.2]"
                    ></div>
                  ))
              : freebies.map((freebie) => (
                  <div
                    key={freebie._id}
                    onClick={() => handleFreebieSelect(freebie)}
                    className="border p-2 rounded-lg w-[10rem] hover:shadow-lg cursor-pointer flex flex-col items-left"
                  >
                    <img
                      src={freebie.image}
                      alt={freebie.name}
                      className="w-full aspect-square object-cover rounded-md"
                    />
                    <p className="text-sm font-semibold mt-2">{freebie.name}</p>
                    <div className="flex items-center gap-1">
                      <div className="text-gray-500 text-xs line-through">
                        ₹{freebie.price}
                      </div>
                      <div className="text-green-500 font-bold text-sm">
                        FREE
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </>
      )}
    </div>
  );
}

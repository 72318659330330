import React from "react";
import { IoMdLocate } from "react-icons/io";

export default function CurrentLocation({ ui2, setStates, states }) {
  const onFetchClick = () => {
    setStates((p) => ({
      ...p,
      loadingCurrentLocation: true,
    }));
    getCurrentLocation(setStates)
      .then((location) => {
        setStates((p) => ({
          ...p,
          lat: location.lat,
          lng: location.lng,
          showMap: true,
          defaultUi: false,
          isChangeMapPosition: true,
          loadingCurrentLocation: false,
        }));
      })
      .catch((error) => {
        console.error("Error:", error);
        setStates((p) => ({
          ...p,
          warningModel: {
            msg: "Something went wrong while fetching your location. Try to search your area",
            rightBtnText: "Ok",
            isRender: true,
          },
          loadingCurrentLocation: false,
        }));
      });
  };
  if (ui2) {
    return (
      <div className="flex items-center gap-1 rounded-xl uppercase px-4 py-1 text-[.875rem] drop-shadow-md font-[500] bg-blue-50 border border-blue-200  text-[#2136d4] ">
        <IoMdLocate className="text-[1rem] relative bottom-[.7px]" />
        {states.loadingCurrentLocation ? (
          <>
            <span className=" cursor-progress" onClick={onFetchClick}>
              Locate me
            </span>
            <div className="loader w-[1rem] ml-2 "> </div>
          </>
        ) : (
          <span onClick={onFetchClick} className=" cursor-pointer">
            Locate me
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex items-center gap-1 mt-1 text-[1rem] font-[500]  text-[#2136d4] ">
        <IoMdLocate className="text-[1rem] relative bottom-[.5px]" />
        {states.loadingCurrentLocation ? (
          <>
            <span className=" cursor-progress" onClick={onFetchClick}>
              Use my current location
            </span>
            <div className="loader w-[1rem] ml-2 "> </div>
          </>
        ) : (
          <span onClick={onFetchClick} className=" cursor-pointer">
            Use my current location
          </span>
        )}
      </div>
    );
  }
}

const getCurrentLocation = (setStates) => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          resolve({ lat, lng });
        },
        (error) => {
          setStates((p) => ({
            ...p,
            warningModel: {
              msg: "Please go to browser setting an enable the location service.",
              rightBtnText: "Ok",
              isRender: true,
            },
          }));
          console.log("Error getting location: " + error.message);
          reject(error);
        }
      );
    } else {
      setStates((p) => ({
        ...p,
        warningModel: {
          msg: "Geolocation is not supported by this browser.",
          rightBtnText: "Ok",
          isRender: true,
        },
      }));
      reject(new Error("Geolocation not supported"));
    }
  });
};

const calculate_offer_discount = (price, { defaultDecorDiscount }) => {
  const offer = defaultDecorDiscount;

  let offerDiscount = 0;
  if (offer && defaultDecorDiscount.isEnabledDiscount) {
    const { discountValue, maxOfferAmountGain } = offer;
    if (
      offer.isRange2 &&
      offer.range2MinOrderValue <= price &&
      offer.range2MaxOrderValue >= price
    ) {
      const discount = Math.round((price * offer?.range2DiscountValue) / 100);
      offerDiscount = Math.min(discount, offer?.range2MaxPriceGain || 100000);
    } else {
      const discount = Math.round((price * discountValue) / 100);
      offerDiscount = Math.min(discount, maxOfferAmountGain); //
    }
  }

  return offerDiscount;
};

export { calculate_offer_discount };

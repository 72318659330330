import moment from "moment";
import React from "react";
import { FaCalendarCheck, FaLocationArrow } from "react-icons/fa";

export default function Header({
  states,
  setStates,
  orderStates,
  setOrderStates,
  sectionInformation,
}) {
  return (
    <div className="flex justify-between relative items-center px-4 flex-shrink-0 h-[3.5rem] border-b ">
      <div>
        <h3 className="text-base font-semibold">
          {sectionInformation[states.currentSection]?.sectionName}
        </h3>
        <p className="text-xs">
          Step {states.currentSection + 1} of {states.maxSections + 1}
        </p>
      </div>

      {/* Compact Order Information Display */}
      <div className="flex flex-col">
        {orderStates.user && (
          <div className="flex  text-sm gap-1">
            <span className="text-gray-500">Customer:</span>{" "}
            {orderStates?.user?.name}
          </div>
        )}
        {orderStates.celebrationDate && (
          <div className="flex  text-xs items-center gap-1">
            <FaCalendarCheck className="text-2xs" />{" "}
            {moment(orderStates.celebrationDate).format("Do, MMM")}
            {orderStates.readyTime &&
              moment(orderStates.readyTime, "hours").format(" - hh:mm A")}
          </div>
        )}
      </div>
      {orderStates.bookingAddress && (
        <div className="bg-white   h-[3.5rem] rounded-b-xl px-4 justify-center absolute bottom-[-3.5rem] border-t border-gray-200 shadow-md left-0 right-0 z-[15]  w-full flex-col text-xs ">
          <div className="flex gap-1 text-[black] capitalize items-center ">
            <FaLocationArrow className="text-sm text-[#2136d4]" />

            <span className="font-[600] capitalize text-sm">
              {orderStates.bookingAddress?.receiversDetails?.name}
            </span>
            <span className="  bg-blue-100 text-[#2136d4] text-2xs  rounded px-2 ">
              {orderStates.bookingAddress?.addressLabel.trim().toLowerCase()}
            </span>
          </div>

          <div className=" text-xs text-[gray] ">
            {orderStates.bookingAddress?.address1 +
              ", " +
              (orderStates.bookingAddress?.address2 &&
                orderStates.bookingAddress?.address2 + ", ") +
              orderStates.bookingAddress?.locationv2?.addressLine1 +
              ", " +
              orderStates.bookingAddress?.locationv2?.addressLine2}
          </div>
        </div>
      )}
    </div>
  );
}

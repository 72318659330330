import React, { useContext } from "react";
import StoreContext from "../../context/StoreContext";

/*
useCase : 
  const { setStoreStates } = useContext(StoreContext);

  setStoreStates(p=> ({...p, navHeadline:{
    component: null, 
    heading: '',
    subHeading: ''
  }}))

*/

export default function TopNavHeadlines() {
  const { storeStates } = useContext(StoreContext);

  // Array of paths that do not need the admin top navbar
  const pathsDoNotNeedAdminTopNavbar = ["/call-logs", "/team/santa/profile/"];

  // Check if the current path includes any of the specified paths
  const shouldShowAdminTopNavbar = !pathsDoNotNeedAdminTopNavbar.some((path) =>
    window.location.pathname.includes(path)
  );
  return;
  if (!shouldShowAdminTopNavbar) {
    return null;
  }

  return (
    <div className="fixed top-1 whitespace-nowrap text-[white]  z-[49] justify-center h-[2.5rem] tracking-[.6px] left-[2.5rem] flex flex-col  ">
      {storeStates?.navHeadline?.component ? (
        storeStates?.navHeadline?.component
      ) : (
        <>
          {storeStates?.navHeadline?.heading ? (
            <div className="font-[500] text-[1rem] max-w-[18rem] md:max-w-[30rem] overflow-hidden text-ellipsis   capitalize ">
              {storeStates?.navHeadline?.heading}
            </div>
          ) : (
            <div
              className="text-[1.5rem] leading-6 text-[white] font-[800]  "
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
            >
              ebo
            </div>
          )}

          {storeStates?.navHeadline?.subHeading && (
            <div className="font-[400] text-[12px]  max-w-[18rem] md:max-w-[30rem] overflow-hidden text-ellipsis capitalize leading-[14px]">
              {storeStates?.navHeadline?.subHeading}
            </div>
          )}
        </>
      )}
    </div>
  );
}

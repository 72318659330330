import axios from "axios";
import { update_addon_by_query_obj } from "./addOnApi";
const BaseUrl = process.env.REACT_APP_API_URL;
// --------------------- To add Product API ---------------------
const addProductAPI = async (product) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/product/addProduct`,
      { product },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return { isSuccess: false };
  }
};
// -------------------To add manyProduct------------------

const AddManyProductAPI = async (productDocs) => {
  const token = localStorage.getItem("ACCESS_TOKEN");

  const res = await axios.post(
    `${BaseUrl}/api/admin/product/addManyProduct`,
    productDocs,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data;
};

// -------------------Delete Pro  duct------------------
const deleteProductsAPI = async (_id) => {
  const token = localStorage.getItem("ACCESS_TOKEN");

  try {
    const response = await axios.post(
      `${BaseUrl}/api/admin/product/deleteProduct`,
      {
        _id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

const get_product_by_query_obj = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
  projectionString,
  tags,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/product/get_product_by_query_obj`,
      {
        tags: tags || [],
        queryObject,
        skip: skip || 0,
        limit: limit || 1000,
        sortingObj: sortingObj || { createdAt: "desc" },
        projectionString: projectionString || "",
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false, isError: true, data: null, message: error };
  }
};

const update_product_by_query_obj = async ({
  queryObject,
  updateObject,
  isAddonProfile,
  tags,
  options,
}) => {
  const token = localStorage.getItem("ACCESS_TOKEN");

  try {
    console.log("isAddonProfile", isAddonProfile);
    if (isAddonProfile) {
      const res = await update_addon_by_query_obj({
        queryObject,
        updateObject,
        tags,
        options,
      });

      return {
        isSuccess: res.isSuccess,
        message: res.message,
        product: res.addon,
      };
    } else {
      const res = await axios.post(
        `${BaseUrl}/api/admin/product/update_product_by_query_obj`,
        { queryObject, updateObject, tags, options },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      return res.data;
    }
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};
const index_update_api = async ({
  _id,
  newIndex,
  keyIndex,
  refProductComporisonKey,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/product/index_update`,
      { _id, newIndex, keyIndex, refProductComporisonKey },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};

const update_multiple_products_by_query_object = async ({
  BaseUrl,
  queryObject,
  updateObject,
}) => {
  const token = localStorage.getItem("ACCESS_TOKEN");

  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/product/update_many_product_by_query_obj`,
      { queryObject, updateObject },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};

// geting the product count type wise for
const get_products_count_type_wise_api = async () => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.get(
      `${BaseUrl}/api/admin/product/v2/decors-count-type-wise`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false };
  }
};
const productDynamicPriceCaculationApi = async ({
  hubId,
  locationId,
  productId,
  isAddonProfile,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/product/v2/productDynamicPriceCaculation`,
      { hubId, locationId, productId, isAddonProfile },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false };
  }
};
const getDynamicPricingForAllHubsForAParticulaHubApi = async ({
  productId,
  isAddonProfile,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/product/v2/getDynamicPricingForAllHubsForAParticulaHub`,
      { productId, isAddonProfile },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false };
  }
};

const add_variant_by_query_obj = async ({ queryObject }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/product/add_variant_by_query_obj`,
      { queryObject },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};

const delete_variant_by_query_obj = async ({ queryObject }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    const res = await axios.post(
      `${BaseUrl}/api/admin/product/delete_variant_by_query_obj`,
      { queryObject },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};

export {
  AddManyProductAPI,
  deleteProductsAPI,
  addProductAPI,
  index_update_api,
  get_product_by_query_obj,
  update_product_by_query_obj,
  update_multiple_products_by_query_object,
  get_products_count_type_wise_api,
  productDynamicPriceCaculationApi,
  getDynamicPricingForAllHubsForAParticulaHubApi,
  add_variant_by_query_obj,
  delete_variant_by_query_obj,
};

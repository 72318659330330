import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdEdit } from "react-icons/md";
import { formatCurrency } from "../../../../utils/adminUtils";
import { s3RemoveApi, s3UploadApi } from "../../../../api/aws/s3";
import { v4 } from "uuid";
import { useStoreContext } from "../../../../context/StoreContext";

let timeoutId;
export default function AddNewAddonsModal({ orderStates, setOrderStates }) {
  const [addonModalStates, setAddonModalStates] = useState({
    searchText: "",
    isLoading: false,
    listOfAddons: [],
    selectedAddon: null,
    category: "",
  });

  const {
    storeStates: { addonCategoryList },
  } = useStoreContext();

  // Debounce function
  const debounce = (func, delay = 800) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    fetchProducts("", "");

    // eslint-disable-next-line
  }, []);

  // Fetch products function
  const fetchProducts = (
    text = addonModalStates.searchText,
    decorCategory = addonModalStates.category
  ) => {
    // Build filter based on text and category
    const filter = {
      ...(text
        ? {
            $or: [
              { _id: { $regex: text, $options: "i" } },
              { addonName: { $regex: text, $options: "i" } },
            ].filter(Boolean),
          }
        : {}),
      ...(decorCategory ? { category: decorCategory } : {}),
      active: true,
    };

    window.v2Crud({
      queries: [
        {
          modelName: "Addon",
          operation: "read",
          sort: { createdAt: -1 },
          limit: 50,
          filter,
          // "_id addonName addonImage category price tags discountedPrice listingPriceHubWise description isText isColor isRent"
          selects: [
            "_id",
            "addonName",
            "addonImage",
            "category",
            "price",
            "tags",
            "discountedPrice",
            "listingPriceHubWise",
            "description",
            "isText",
            "isColor",
            "isRent",
          ],
        },
      ],
      onInitiate: () => {
        setAddonModalStates((p) => ({ ...p, isLoading: true }));
      },
      onSuccess: (res) => {
        // setProducts(res?.data?.at(0)?.data || []);
        setAddonModalStates((p) => ({
          ...p,
          listOfAddons: res?.data?.at(0)?.data || [],
        }));
        setAddonModalStates((p) => ({ ...p, isLoading: false }));
      },
      onError: (error) => {
        console.error("Error fetching products:", error);
        setAddonModalStates((p) => ({ ...p, isLoading: false }));
      },
    });
  };

  // Debounced search function
  const debouncedSearch = debounce((text, decorCategory) =>
    fetchProducts(text, decorCategory)
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const text = e.target.value;
    setAddonModalStates((p) => ({ ...p, searchText: text, isLoading: true }));
    debouncedSearch(text);
  };

  const handleAddToOrder = async () => {
    const { selectedAddon } = addonModalStates;
    if (!selectedAddon) {
      window.toast("Please select an addon", "error");
      return;
    }

    // empty text validation
    if (selectedAddon.isText && !selectedAddon.text) {
      window.toast("Text can't be empty", "error");
      return;
    }

    if (!selectedAddon.qty) {
      window.toast("Quantity can't be 0", "error");
      return;
    }

    // validation on name, selling price, cost price
    if (!selectedAddon.addonName) {
      window.toast("Addon name can't be empty", "error");
      return;
    }
    // validation on name, selling price, cost price
    if (selectedAddon.category === "") {
      window.toast("Addon category can't be empty", "error");
      return;
    }

    if (
      typeof parseInt(selectedAddon.sellingPrice) !== "number" ||
      typeof parseInt(selectedAddon.costPrice) !== "number"
    ) {
      window.toast("Mentioned price for add-on is wrong", "error");
      return;
    }

    const newAddon = {
      addonId: {
        _id: selectedAddon._id || "custom",
        addonName: selectedAddon.addonName,
        addonImage: selectedAddon.addonImage,
        description: selectedAddon.description,

        price: selectedAddon.costPrice,
        discountedPrice: selectedAddon.sellingPrice,
        category: selectedAddon.category,
      },
      text: selectedAddon.text,
      quantity: selectedAddon.qty,
      color: selectedAddon.color,
    };

    window.loadingScreen(true, "Adding addon to order...");

    const addonsArray = [...orderStates.addOns];

    setOrderStates((p) => ({
      ...p,
      addOns: [...addonsArray, newAddon],
    }));

    try {
    } catch (error) {
      console.log(error);
      window.toast("Something went wrong", "error");
    }
    window.loadingScreen(false);
    window.floatingModal(false);
  };

  const handleImageChange = (e) => {
    try {
      const imageFile = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        handleImageUploadToS3(imageFile);
      };
      reader.readAsDataURL(imageFile);
    } catch (error) {
      console.error("Error checking camera permission:", error);
    }
  };

  const handleImageUploadToS3 = async (imageFile) => {
    window.loadingScreen(true, "Uploading image...");
    try {
      const currentImageUrl = addonModalStates?.selectedAddon?.addonImage;
      const idx = v4();
      await s3UploadApi([
        {
          file: imageFile,
          path: `orders/addons/${idx}.webp`,
        },
      ]);

      const newImageUrl = `https://img.ebonow.com/custom/orders/addons/${idx}.webp`;

      if (currentImageUrl?.includes("custom/orders")) {
        const imagePathToRemove = currentImageUrl.split("custom/")[1];
        window.loadingScreen(true, "Deleting existing image...");
        await s3RemoveApi([imagePathToRemove]);
      }
      setAddonModalStates((p) => ({
        ...p,
        selectedAddon: {
          ...p.selectedAddon,
          addonImage: newImageUrl,
        },
      }));
    } catch (error) {
      console.log(error);
      window.toast("Something went wrong", "error");
    }
    window.loadingScreen(false);
  };

  return (
    <div className="w-[100vw] md:w-[30rem] h-[100dvh] flex flex-col bg-white drop-shadow-md ">
      <div className="w-full blue-gradient px-4">
        <div className="w-full py-4 flex justify-between  ">
          <div className="text-lg font-[600]">Add-on add in order</div>
          <RxCross2
            onClick={() => {
              window.floatingModal(false);
            }}
            className="text-xl"
          />
        </div>
        {/* search bar */}
        {!addonModalStates?.selectedAddon && (
          <div>
            <div className="relative w-full mb-2">
              <input
                className="w-full bg-white rounded-full focus:outline-none px-4 py-3 text-sm text-black "
                value={addonModalStates.searchText}
                onChange={handleSearchChange}
                placeholder="Search Addons..."
              />
            </div>
            <div className="flex items-center justify-between text-black w-full mt-3 mb-4">
              <select
                value={addonModalStates.category}
                onChange={(e) => {
                  setAddonModalStates((p) => ({
                    ...p,
                    category: e.target.value,
                    isLoading: true,
                  }));
                  debouncedSearch(addonModalStates.searchText, e.target.value);
                }}
                className="p-1 px-2 capitalize bg-blue-50 rounded-lg focus:outline-none  text-xs md:text-sm border border-blue-300"
              >
                <option value="">All categories</option>
                {/* options of the categories and value selection of code */}
                {addonCategoryList.map((category) => (
                  <option key={category.code} value={category.code}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
              <button
                onClick={() => {
                  setAddonModalStates((p) => ({
                    ...p,
                    searchText: "",
                    isLoading: false,
                    listOfAddons: [],
                    selectedAddon: {
                      addonName: "",
                      addonImage: "",
                      sellingPrice: "",
                      costPrice: "",
                      qty: 1,
                      text: "",
                      category: "",
                    },
                    category: "",
                  }));
                }}
                className=" px-4 py-1 rounded-lg bg-blue-50 cursor-pointer "
              >
                Create new
              </button>
            </div>
          </div>
        )}
      </div>

      {/* no addons found  */}
      {addonModalStates?.isLoading && (
        <ul className="divide-y transition-all overflow-scroll pb-[2rem] h-full px-4 ">
          {Array(15)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="p-3 bg-gray-200 rounded-lg skeleton w-full flex-shrink-0 h-[5rem]  my-3 "
              ></div>
            ))}
        </ul>
      )}

      {!addonModalStates?.isLoading &&
        addonModalStates?.listOfAddons?.length > 0 &&
        !addonModalStates?.selectedAddon && (
          <ul className="divide-y transition-all overflow-scroll pb-[2rem] h-full px-4 ">
            {addonModalStates?.listOfAddons?.map((item, idx) => {
              const productPriceDetail = item.listingPriceHubWise?.find(
                (currHub) => currHub.hub === orderStates?.hubId
              );

              const handleSelect = () => {
                setAddonModalStates((p) => ({
                  ...p,
                  selectedAddon: {
                    ...item,
                    sellingPrice: productPriceDetail?.sellingPrice,
                    costPrice: productPriceDetail?.costPrice,
                    qty: item?.isText ? 0 : 1,
                    text: "",
                  },
                }));
              };

              return (
                <li
                  onClick={handleSelect}
                  key={idx}
                  className="py-3 flex items-start justify-between"
                >
                  <div className="flex gap-3">
                    <img
                      src={item.addonImage}
                      className="size-[3.5rem] rounded-md border border-gray-200"
                      alt=""
                    />
                    <div>
                      <span className="block text-sm cursor-pointer text-gray-700 font-[500] leading-4">
                        {item.addonName}
                      </span>
                      <span className="  text-xs flex gap-1 items-center text-gray-600">
                        {item.subTitle}
                        {[
                          item.isText && "Text",
                          item.isColor && "Color",
                          item.isRent && "Rental",
                        ]
                          .filter((_) => _)
                          .map((curr, idx) => {
                            return (
                              <>
                                {idx !== 0 && (
                                  <div className="gray-gradient rounded-full p-[2px]"></div>
                                )}
                                <span key={idx} className="text-gray-500">
                                  {curr}
                                </span>
                              </>
                            );
                          })}
                      </span>
                    </div>
                  </div>
                  <div className="text-base font-[600] pl-1 cursor-pointer ">
                    {typeof productPriceDetail?.sellingPrice !== "number"
                      ? "-"
                      : formatCurrency(productPriceDetail?.sellingPrice)}
                  </div>
                </li>
              );
            })}
          </ul>
        )}

      {/* selected addon */}
      {addonModalStates?.selectedAddon && (
        <div className="flex flex-col w-full p-6 bg-white rounded-lg shadow-md">
          {/* Choose Another Button */}
          <div className="w-full flex justify-end mb-6">
            <button
              onClick={() => {
                setAddonModalStates((p) => ({
                  ...p,
                  selectedAddon: null,
                }));
              }}
              className="border rounded-lg px-4 py-2 text-red-600 bg-red-50 hover:bg-red-100 transition-colors text-xs"
            >
              Choose another
            </button>
          </div>

          {/* Addon Details */}
          <div className="flex w-full gap-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
            {/* Image Section */}
            <div className="flex flex-col items-center w-[5rem]">
              <img
                className="w-full rounded-lg border border-gray-300 aspect-square"
                alt="Addon"
                onClick={() =>
                  window.showMsg(
                    addonModalStates?.selectedAddon?.addonImage,
                    "img"
                  )
                }
                src={addonModalStates?.selectedAddon?.addonImage}
              />
              <button
                onClick={() =>
                  document.getElementById("addonImageInput").click()
                }
                className="mt-2 text-blue-600 text-xs flex items-center gap-1"
              >
                <MdEdit /> Change Image
              </button>
              <input
                id="addonImageInput"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>

            {/* Addon Info */}
            <div className="flex flex-col flex-1">
              <input
                placeholder="Addon name..."
                className="w-full text-sm border border-gray-300 px-3 py-2 rounded-lg focus:outline-none  "
                value={addonModalStates?.selectedAddon?.addonName}
                onChange={(e) =>
                  setAddonModalStates((p) => ({
                    ...p,
                    selectedAddon: {
                      ...p.selectedAddon,
                      addonName: e.target.value,
                    },
                  }))
                }
              />
              <div className="w-full flex gap-4mt-4">
                {/* Customer Price */}
                <div className="flex-1">
                  <label className="text-sm  whitespace-nowrap  text-gray-500">
                    Customer Price
                  </label>
                  <input
                    value={addonModalStates?.selectedAddon?.sellingPrice}
                    onChange={(e) =>
                      setAddonModalStates((p) => ({
                        ...p,
                        selectedAddon: {
                          ...p.selectedAddon,
                          costPrice: ((100 * e.target.value) / 75)?.toFixed?.(
                            2
                          ),
                          sellingPrice: e.target.value,
                        },
                      }))
                    }
                    className="w-full text-sm border border-gray-300 px-3 py-2 rounded-lg focus:outline-none  "
                  />
                </div>

                {/* Category Dropdown */}
                <div className="flex-1">
                  <label className="text-sm text-gray-500">Category</label>
                  <select
                    value={addonModalStates?.selectedAddon?.category}
                    onChange={(e) => {
                      setAddonModalStates((p) => ({
                        ...p,
                        selectedAddon: {
                          ...p.selectedAddon,
                          category: e.target.value,
                        },
                      }));
                    }}
                    className="w-full text-sm border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  >
                    <option value="">Select Category</option>
                    {addonCategoryList.map((category) => (
                      <option key={category.code} value={category.code}>
                        {category.categoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Quantity/Content Section */}
          <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
            <div className="flex items-center w-full mb-4 border border-gray-300 rounded-lg">
              <select
                value={addonModalStates?.selectedAddon?.isText ? "text" : "qty"}
                onChange={(e) =>
                  setAddonModalStates((p) => ({
                    ...p,
                    selectedAddon: {
                      ...p.selectedAddon,
                      isText: e.target.value === "text",
                      text: "",
                      qty: 1, // Default quantity
                    },
                  }))
                }
                className="text-sm border-r border-gray-300 px-3 py-2 bg-gray-50 rounded-l-lg focus:outline-none"
              >
                <option value="text">Content</option>
                <option value="qty">Quantity</option>
              </select>
              <input
                type={
                  addonModalStates?.selectedAddon?.isText ? "text" : "number"
                }
                value={
                  addonModalStates?.selectedAddon?.isText
                    ? addonModalStates?.selectedAddon?.text
                    : addonModalStates?.selectedAddon?.qty
                }
                onChange={(e) => {
                  if (addonModalStates?.selectedAddon?.isText) {
                    const textLength = e.target.value.replace(
                      /\s+/g,
                      ""
                    ).length;
                    setAddonModalStates((p) => ({
                      ...p,
                      selectedAddon: {
                        ...p.selectedAddon,
                        text: e.target.value,
                        qty: textLength, // Update qty based on text length
                      },
                    }));
                  } else {
                    setAddonModalStates((p) => ({
                      ...p,
                      selectedAddon: {
                        ...p.selectedAddon,
                        qty: e.target.value,
                      },
                    }));
                  }
                }}
                className="text-sm w-full px-3 py-2 bg-white rounded-r-lg focus:outline-none "
                placeholder={
                  addonModalStates?.selectedAddon?.isText
                    ? "Text here..."
                    : "Quantity"
                }
              />
            </div>
            <div className="flex justify-between items-center">
              <span className="font-medium text-gray-500">Total</span>
              <span className="font-medium text-black">
                {formatCurrency(
                  (parseInt(addonModalStates?.selectedAddon?.qty) || 0) *
                    addonModalStates?.selectedAddon?.sellingPrice
                )}
              </span>
            </div>
          </div>

          {/* Add Button */}
          <div className="w-full flex justify-end mt-6">
            <button
              onClick={handleAddToOrder}
              className="w-full px-4 py-3 bg-gradient-to-r from-blue-400 to-blue-600 text-white rounded-lg shadow-md hover:opacity-90 transition"
            >
              Add
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
import { BsSearch } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
let timeoutId;
export default function TextSearchBar({
  states,
  minSearchTextLengthToStartSearch,
  setStates,
  queryDebounceTime,
  autocompleteService,
  country,
}) {
  const debounce = (func, delay = queryDebounceTime) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = (text) => {
    if (
      states.query < minSearchTextLengthToStartSearch ||
      text.length < minSearchTextLengthToStartSearch
    ) {
      setStates((p) => ({
        ...p,
        loadingPredictions: false,
        isPredictionsShow: false,
        showServiceCites: true,
      }));
      return;
    }
    setStates((p) => ({
      ...p,
      loadingPredictions: true,
      isPredictionsShow: true,
      showServiceCites: false,
    }));

    autocompleteService.getPlacePredictions(
      {
        input: states.query,
        origin: { lat: 0, lng: 0 },
        componentRestrictions: {
          country: country,
        },
      },
      handleSuggestions
    );
  };

  const debouncedSearch = debounce((text) => handleSearch(text));

  const handleSuggestions = (predictions, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      // setPredictions([]);
      console.log(" no predictions");
      return;
    }
    // setPredictions(predictions);
    setStates((p) => ({
      ...p,
      predictions: predictions,
      loadingPredictions: false,
    }));
  };

  return (
    <div className="relative z-[1] w-[95%]">
      <BsSearch className="absolute left-[.8rem]  cursor-pointer text-[1.1rem] text-[#707070] top-[1.5rem] z-[2]" />
      <input
        value={states.query}
        className="input2 text-[1rem]  p-4 absolute w-full px-10"
        placeholder="Search for area, street, city..."
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            query: e.target.value,
            defaultUi: true,
            serviceAvailable: false,
            loadingPointLocation: false,
            loadingReverseGeoCode: false,
            noServiceAvailable: false,
          }));
          if (e.target.value.length >= minSearchTextLengthToStartSearch) {
            debouncedSearch(e.target.value);
          } else {
            clearTimeout(timeoutId);
            setStates((p) => ({
              ...p,
              predictions: [],
              isPredictionsShow: false,
            }));
          }
        }}
      />
      {states.query &&
        states.query.trim().length < minSearchTextLengthToStartSearch && (
          <div className=" absolute right-[1rem] text-[#aeaeae] top-[.95rem] z-[2]">
            Type {minSearchTextLengthToStartSearch - states.query.trim().length}{" "}
            more words
          </div>
        )}

      {states.query.length > minSearchTextLengthToStartSearch &&
        !states.loadingPredictions && (
          <RxCross2
            onClick={() => {
              setStates((p) => ({
                ...p,
                query: "",
                predictions: [],
                defaultUi: true,
                loadingPredictions: false,
                isPredictionsShow: false,
                showServiceCites: true,
                loadingCurrentLocation: false,
                loadingPointLocation: false,
                loadingReverseGeoCode: false,
                serviceAvailable: false,
                noServiceAvailable: false,
                loadingLocation: false,
              }));
            }}
            className="absolute right-[1rem] cursor-pointer text-[1rem] text-[#707070] top-[1.1rem] z-[2]"
          />
        )}
      {states.loadingPredictions && (
        <div className="absolute right-[1rem] loader cursor-pointer w-[1rem] top-[1rem] z-[2]" />
      )}
    </div>
  );
}

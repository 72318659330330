import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { v4 as uuidv4 } from "uuid";
import { s3RemoveApi, s3UploadApi } from "../../../../api/aws/s3";
import { useStoreContext } from "../../../../context/StoreContext";

export default function ProductForm({
  formData,
  handleInput,
  handleSubmitbtn,
  productSpinner,
  decorImages,
  setDecorImages,
  setFormData,
}) {
  const {
    storeStates: { decorCategoryList },
  } = useStoreContext();

  const [customerPriceAnalysis, setCustomerPriceAnalysis] = useState({
    finalCustomerPrice: formData?.sellingPrice || 0,
    extraDiscount: "",
    prepaidDiscount: "",
  });

  const handlePriceAnalysisChange = (e) => {
    const { name, value } = e.target;
    setCustomerPriceAnalysis((prev) => {
      const updatedAnalysis = { ...prev, [name]: value };
      const extraDiscountAmount =
        (formData.sellingPrice *
          (parseFloat(updatedAnalysis.extraDiscount) || 0)) /
        100;
      const finalPrice =
        formData.sellingPrice -
        extraDiscountAmount -
        (parseFloat(updatedAnalysis.prepaidDiscount) || 0);

      return {
        ...updatedAnalysis,
        finalCustomerPrice: Math.max(0, finalPrice) || formData.sellingPrice, // Default to sellingPrice if no discount
      };
    });
  };

  const handleImageChange = async (event) => {
    try {
      const files = event.target.files;
      const newDecorImagesArray = [...decorImages];
      const imagesToUpload = [];

      window.loadingScreen(true, "Uploading images...");
      for (const file of files) {
        const idx = uuidv4();
        imagesToUpload.push({
          file: file,
          path: "orders/decors/" + idx + ".webp",
        });
        newDecorImagesArray.push(
          "https://img.ebonow.com/custom/orders/decors/" + idx + ".webp"
        );
      }
      if (imagesToUpload.length > 0) {
        await s3UploadApi(imagesToUpload);
        setDecorImages(newDecorImagesArray);
      }
      window.loadingScreen(false);
    } catch (error) {
      window.loadingScreen(false);
      console.error("Error uploading image:", error);
    }
  };

  const handleRemoveImage = async (imageInfo) => {
    try {
      window.loadingScreen(true, "Removing image...");
      if (imageInfo.includes("https://img.ebonow.com/custom")) {
        const imagePath = imageInfo.split("https://img.ebonow.com/custom/");
        const newDecorImagesArray = decorImages.filter(
          (curr) => curr !== imageInfo
        );
        setDecorImages(newDecorImagesArray);
        await s3RemoveApi([imagePath[1]]);
      }
      window.loadingScreen(false);
    } catch (error) {
      window.loadingScreen(false);
      console.error("Error removing image:", error);
    }
  };

  return (
    <form onSubmit={handleSubmitbtn} className="flex-col space-y-6">
      {/* Product Name */}
      <label className="flex flex-col">
        <span className="font-medium mb-1">Product Name</span>
        <input
          type="text"
          placeholder="Enter Product Name"
          value={formData.name}
          onChange={handleInput}
          name="name"
          className="p-2 border border-gray-300 rounded-md"
        />
      </label>

      {/* Category and Price */}
      <div className="grid grid-cols-2 gap-4">
        <label className="flex flex-col">
          <span className="font-medium mb-1">Category</span>
          <select
            name="ocassionCategory"
            value={formData.ocassionCategory}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                ocassionCategory: e.target.value,
              }))
            }
            className="p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select Category</option>
            {decorCategoryList.map((category) => (
              <option key={category.code} value={category.code}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </label>

        <label className="flex flex-col">
          <span className="font-medium mb-1">Customer Price</span>
          <input
            type="number"
            placeholder="Enter Customer Price"
            value={formData.sellingPrice}
            onChange={handleInput}
            name="sellingPrice"
            className="p-2 border border-gray-300 rounded-md"
          />
        </label>
      </div>

      {/* Image Upload */}
      <div className="flex flex-col items-start">
        <span className="font-medium mb-1">Upload Images</span>
        <input
          type="file"
          accept="image/jpeg, image/png, image/webp"
          multiple={true}
          onChange={handleImageChange}
          className="mb-4 "
        />
        <div className="flex gap-3 flex-wrap">
          {decorImages.map((curr, idx) => (
            <div
              key={idx}
              className="relative rounded-md overflow-hidden h-24 w-24 border shadow-sm"
            >
              <RxCross2
                onClick={() => handleRemoveImage(curr)}
                className="absolute top-1 right-1 cursor-pointer text-white bg-red-600 rounded-full p-1 shadow"
              />
              <img
                src={curr}
                alt="decor"
                className="w-full h-full object-cover rounded-md"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="p-2 w-full bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center justify-center"
      >
        {productSpinner ? "Saving..." : "Save"}
      </button>

      <div className="p-4 mt-2 border border-gray-300 rounded-md bg-gray-50">
        <h3 className="font-semibold text-lg mb-3">Customer Price Analysis</h3>
        <div className="grid grid-cols-3 gap-4 items-end">
          <label className="flex flex-col">
            <span className="text-sm font-medium">Extra Discount (%)</span>
            <input
              type="number"
              name="extraDiscount"
              placeholder="Enter %"
              value={customerPriceAnalysis.extraDiscount}
              onChange={handlePriceAnalysisChange}
              className="p-2 border border-gray-300 rounded-md"
            />
          </label>

          <label className="flex flex-col">
            <span className="text-sm font-medium">Prepaid Discount (₹)</span>
            <input
              type="number"
              name="prepaidDiscount"
              placeholder="Enter amount"
              value={customerPriceAnalysis.prepaidDiscount}
              onChange={handlePriceAnalysisChange}
              className="p-2 border border-gray-300 rounded-md"
            />
          </label>

          <div className="flex flex-col">
            <span className="text-sm font-medium">
              Final Customer Price (₹)
            </span>
            <div className="p-2 border border-gray-300 rounded-md bg-gray-100">
              ₹{customerPriceAnalysis?.finalCustomerPrice?.toFixed?.(2)}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

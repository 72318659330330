import { create_user_access_token } from "../../api/userApi";

/** to make a call */
export const makeACall = (phoneNumber) => {
  // Check if the device supports the "tel" URI scheme
  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    // Use the "tel" URI scheme to open the telephone app with the specified phone number
    window.open(`tel:${phoneNumber}`, "_self");
  } else {
    // Display an error message if the device does not support phone calls
    alert("Phone calls are not supported on this device.");
  }
};

export function composeEmail(emailAddress) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailPattern.test(emailAddress)) {
    // Use the "mailto" URI scheme to open the default email client with the specified email address
    window.open(`mailto:${emailAddress}`);
  } else {
    // Display an alert for an invalid email address
    alert("Invalid email address. Please enter a valid email address.");
  }
}

export const handleGrantAccessCLick = async (userId) => {
  const res = await create_user_access_token({
    userId,
  });
  console.log(res.url);
  // if (res.isSuccess) window.open(res.url);
};

// Utility function to extract latitude and longitude from a DMS string
export const extractCoordinatesFromDMS = (dmsString) => {
  const latLngParts = dmsString.split(/[+ ]/); // Split on "+" or space
  if (latLngParts.length !== 2) {
    throw new Error("Invalid DMS format");
  }

  const lat = dmsToDecimal(latLngParts[0]);
  const lng = dmsToDecimal(latLngParts[1]);

  return { lat, lng };
};

// Converts DMS to Decimal Degrees, or returns the decimal value if it's already in decimal degrees
export const dmsToDecimal = (input) => {
  // Regular expression to check if the input contains degrees symbol or typical DMS format
  const isDMS = /[°'"]/;

  if (!isDMS.test(input)) {
    // If the input does not contain DMS symbols, return as decimal
    const decimal = parseFloat(input);
    if (isNaN(decimal)) {
      throw new Error("Invalid decimal format");
    }
    return decimal;
  }

  // If it contains DMS symbols, convert to decimal degrees
  const parts = input.split(/[^\d\w.]+/); // Split by non-alphanumeric characters

  if (parts.length < 4) {
    throw new Error("Invalid DMS format");
  }

  const degrees = parseFloat(parts[0]);
  const minutes = parseFloat(parts[1]) / 60;
  const seconds = parseFloat(parts[2]) / 3600;
  const direction = parts[3]; // N, S, E, W

  let decimal = degrees + minutes + seconds;

  if (direction === "S" || direction === "W") {
    decimal *= -1; // Adjust for South or West direction
  }

  return decimal;
};

// Example usage
// console.log(dmsToDecimal("24°46'23.8\"N")); // Output in decimal degrees
// console.log(dmsToDecimal("24.7733")); // Output as it is


// Convert Decimal Degrees to DMS
export const decimalToDMS = (decimal, isLat = true) => {
  const degrees = Math.floor(Math.abs(decimal));
  const minutes = Math.floor((Math.abs(decimal) - degrees) * 60);
  const seconds = ((Math.abs(decimal) - degrees) * 60 - minutes) * 60;

  const direction = isLat
    ? decimal >= 0
      ? "N"
      : "S"
    : decimal >= 0
    ? "E"
    : "W";

  return `${degrees}°${minutes}'${seconds.toFixed(1)}"${direction}`;
};

import React from "react";
import CurrentLocation from "./CurrentLocation";
import ServiceableCities from "./ServiceableCities";
import PredictionsList from "./PredictionsList";
export default function DefaultLocationSuggestions({ states, setStates }) {
  if (states.defaultUi) {
    return (
      <div className="flex flex-col bg-white h-full overflow-scroll pt-[4rem]  w-full ">
        <div className="px-4 mt-4">
          <CurrentLocation states={states} setStates={setStates} />
        </div>
        <div className="px-4 pt-4">
          <PredictionsList states={states} setStates={setStates} />
        </div>

        <ServiceableCities states={states} setStates={setStates} />
      </div>
    );
  }
}

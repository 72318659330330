import React, { useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import moment from "moment";

export default function AskForInputs1({ parentStates, setParentStates }) {
  const handleClose = async () => {
    setParentStates((p) => ({
      ...p,
      askForInputs: {
        isRender: false,
        labels: [],
        values: [],
        heading: "",
        subHeading: "",
        onSubmitCallback: null,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.askForInputs.isRender && (
        <Modal
          handleClose={handleClose}
          parentStates={parentStates.askForInputs}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, parentStates }) => {
  const values = useMemo(() => {
    const { labels, values } = parentStates;
    const obj = {};
    for (let i = 0; i < labels?.length; i++) {
      if (obj[labels?.[i]] || 0) {
        obj[labels?.[i]] = `${values[i]}_${moment().format("DD_MM_YYYY")}`;
      }
    }
    return obj;
  }, [parentStates]);

  console.log(values);

  const [states, setStates] = useState({ ...values });

  return (
    <div className="fixed top-0 z-[98] flex items-end justify-end md:justify-start right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: "0%", opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center justify-center w-full md:w-[30rem]  flex-col bg-[white] rounded-lg   "
      >
        <div className=" w-full flex items-center justify-between p-4 border-b border-gray-200 pb-2 mb-2 ">
          <div className="flex flex-col ">
            <p className="text-[1.125rem] font-[500] ">
              {parentStates.heading}
            </p>
            <p className="text-[.875rem] text-[#6b6b6b] ">
              {parentStates.subHeading}
            </p>
          </div>
          <div onClick={handleClose} className="cursor-pointer ">
            <RxCross1 />
          </div>
        </div>
        <div className=" w-full flex flex-col px-4 pt-4 max-h-[80vh] overflow-scroll text-[.875rem] ">
          {parentStates?.labels?.map((curr, idx) => {
            return (
              <div key={idx} className="flex flex-col w-full">
                <div className="text-[gray] text-[.75rem] ">{curr}</div>
                <input
                  className="input2 p-2 mb-2"
                  onChange={(e) =>
                    setStates((p) => ({ ...p, [curr]: e.target.value }))
                  }
                  value={states[curr] || ""}
                />
              </div>
            );
          })}
        </div>

        <div className="flex p-4  w-full items-end justify-end gap-2">
          <button
            onClick={async () => {
              await parentStates.onSubmitCallback(
                parentStates?.labels?.map((curr) => states[curr])
              );
              handleClose();
            }}
            className="text-[1rem] cursor-pointer font-[500] blue-gradient   p-2 px-6 rounded-lg"
          >
            {parentStates.saveBtnTxt}
          </button>
        </div>
      </motion.div>
    </div>
  );
};

import React from "react";
import { MdDelete } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { handelPlaceOrder } from "./placeManualOrder";
import { useAdminContext } from "../../../../context/AdminContext";

export default function BottomButtons({
  states,
  orderStates,
  setStates,
  setOrderStates,
  orderTotal,
  resetOrderStates,
}) {
  const nav = useNavigate();

  const {
    adminState: { adminInfo },
  } = useAdminContext();

  const [placingOrder, setPlacingOrder] = React.useState(false);
  const handleNext = () => {
    if (canNext({ orderStates, states })) {
      if (states.currentSection < states.maxSections) {
        setStates((prevState) => ({
          ...prevState,
          currentSection: prevState.currentSection + 1,
        }));
      }
    }
  };

  const handlePrevious = () => {
    if (states.currentSection > 0) {
      setStates((prevState) => ({
        ...prevState,
        currentSection: prevState.currentSection - 1,
      }));
    }
  };

  const handleReset = () => {
    window.warningModal({
      msg: "Are you sure you want to reset? All progress will be lost.",
      rb_text: "Confirm",
      lb_text: "Cancel",
      rb_fn: () => {
        resetOrderStates();
        setStates({ currentSection: 0, maxSections: 5 });
        localStorage.removeItem("MOP_orderData");
      },
    });
  };

  const handleCancelOrderPlace = () => {
    if (orderStates.user) {
      window.warningModal({
        msg: "Sure to cancel order placement?",
        rb_text: "Confirm",
        lb_text: "Cancel",
        rb_fn: () => {
          resetOrderStates();
          setStates({ currentSection: 0, maxSections: 5 });
          localStorage.removeItem("MOP_orderData");
        },
      });
    } else {
      localStorage.removeItem("MOP_orderData");
      nav("/");
    }

    // Redirect or navigate to the home page (e.g., navigate("/home") if using react-router)
  };

  return (
    <div className="w-full flex justify-between items-center flex-shrink-0 px-4 border-t border-gray-200 h-[3.5rem] text-sm ">
      <div className="flex gap-4 items-center">
        <RxCross2
          onClick={handleCancelOrderPlace}
          className=" text-red-600 text-xl cursor-pointer"
        />
        <MdDelete
          onClick={handleReset}
          className=" text-red-600 text-xl cursor-pointer"
        />
      </div>
      <div className="flex gap-2 items-center">
        {states.currentSection > 0 && (
          <button
            className="bg-gray-300 px-6 py-2 rounded-full "
            onClick={handlePrevious}
          >
            Previous
          </button>
        )}
        {states.currentSection < states.maxSections ? (
          <button
            className="blue-gradient px-6 py-2 rounded-full "
            onClick={handleNext}
          >
            Next
          </button>
        ) : (
          <button
            className="blue-gradient px-4 py-2 rounded-full"
            onClick={async () => {
              setPlacingOrder(true);
              const res = await handelPlaceOrder(
                orderStates,
                orderTotal,
                adminInfo
              );
              if (res.isSuccess) {
                nav("/order/order-info/" + res.bookingId);
              }
              setPlacingOrder(false);
            }}
          >
            {placingOrder ? (
              <div className=" flex items-center gap-1 ">
                <div
                  className="loader w-[1rem]"
                  style={{
                    borderTopColor: "white",
                  }}
                ></div>{" "}
                Placing...
              </div>
            ) : (
              "Place Order"
            )}
          </button>
        )}
      </div>
    </div>
  );
}
const canNext = ({ orderStates, states }) => {
  const { currentSection } = states;

  // Section-specific validation
  switch (currentSection) {
    case 0: // First section: Requires `user`
      if (!orderStates.user) {
        window.toast("Please select a user before proceeding.");
        return false;
      }
      break;

    case 1: // Second section: Requires `bookingAddress`
      if (!orderStates.bookingAddress) {
        window.toast("Please select an address before proceeding.");
        return false;
      }
      break;

    case 2: // Third section: Requires `celebrationDate`, `readyTime`, and `arrivingTime`
      if (!orderStates.celebrationDate) {
        window.toast("Please select a celebration date before proceeding.");
        return false;
      }
      if (
        typeof orderStates.readyTime !== "number" ||
        orderStates.readyTime < 0 ||
        orderStates.readyTime > 23
      ) {
        window.toast("Please specify the ready time before proceeding.");
        return false;
      }
      if (
        typeof orderStates.readyTime !== "number" ||
        orderStates.readyTime < 0 ||
        orderStates.readyTime > 23
      ) {
        window.toast("Please specify the arriving time before proceeding.");
        return false;
      }
      break;

    case 3: // Fourth section: Requires at least one product in `decorImages`
      if (!orderStates.product) {
        window.toast("Please select the product before proceeding.");
        return false;
      }
      break;

    default:
      return true;
  }

  // If all checks pass, allow proceeding to the next section
  return true;
};
